import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { AccountMetaData, setCookie, dimens, getPicture, domainSaveCookie } from '../../config/app';
import { fetchData, requestApiFb } from '../../lib/apis';

import { Header, SiderBar, UpgradePackage, DetailPayment } from '../../component';
import {
    HomeView, ResultView, GuidesView, FilterFileView, PackageView, HistoryView, MeView,
    OwnerTransactionView, ManageUserView, ScanCommentView, ScanDataCommentView, MeTokenView, FindUidView,
    ProxyView, HomeView2
} from '../index';

import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import io from 'socket.io-client';
import moment from 'moment';

export default class MainlayoutView extends Component {
    constructor(props) {
        super(props);
        if (!AccountMetaData.checkIssetLogin()) {
            // AccountMetaData.logIn();
        }
        this.state = {
            user: null,
            loading: false,
            service: null
        };
        this.name_service = {
            "search_fanpage": "Tìm fanpage",
            "me_likes": "Quét fanpage UID thích",
            "scan_interactive_fanpage": "Tương tác fanpage",
            "scan_member_group": "Quét member group",
            "scan_interactive_group": "Tương tác group",
            "scan_phone": "Convert UID -> SDT",
            "scan_interactive_post": "Tương tác bài viết",
            "scan_friends": "Danh sách bạn bè",
            "search_group": "Quét nhóm theo từ khóa",
            "me_groups": "Quét nhóm của UID",
            "info_uid": "Chi tiết UID",
            "scan_comment": "Bình luận của bài viết",
            "scan_comment_hidden": "Quét comment ẩn"
        }
        this.formatExport = {
            "id": "Id",
            "uid": "Id",
            "name": "Tên",
            "avatar": "Avatar",
            "email": "Email",
            "phone": "Phone",
            "likes": "Số like",
            "link": "Link",
            "location": "Địa chỉ",
            "profile_picture.uri": "Avatar",
            "node.name": "Tên",
            "node.id": "Id",
            "node.profile_picture.uri": "Avatar",
            "type_phone": "Loại mạng",
            "emails": "Email",
            "count_like": "Lượt like",
            "count_comment": "Lượt comment",
            "count_reply": "Lượt reply",
            "count_share": "Lượt share",
            "gender": "Giới tính",
            "bio_text.text": "Giới thiệu",
            "mutual_friends.count": "Bạn chung",
            "friendship_status": "Kết bạn",
            "subscribe_status": "Theo dõi",
            "followers_count": "Theo dõi",
            "page_created_time": "Ngày tạo",
            "created_time": "Ngày tạo",
            "member_count": "Thành viên",
            "privacy": "Loại nhóm",
            "description": "Mô tả",
            "viewer_join_state": "Trạng thái join",
            "category": "Danh mục",
            "products": "Sản phẩm",
            "about": "Giới thiệu",
            "like_time": "Ngày like",
            "birthday": "Ngày sinh",
            "locale": "Locale",
            "address": "Địa chỉ",
            "__typename": "Loại tài khoản",
            "email_in_content": "Email",
            "phone_in_content": "Phone",
            "content": "Nội dung",
            "from.id": "UID",
            "from.name": "Tên",
            "created_time_fb": "Ngày tạo",
            "post_id": "Post ID",
            "comment_id": "Comment ID"
        }
    }
    componentWillMount = async () => {
        try {
            if (!this.props.user) await this.props.getInfoUser();
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: process.env.REACT_APP_FB_ID,
                    cookie: true,
                    xfbml: true,
                    version: 'v8.0'
                });
            }.bind(this);

        } catch (err) {
            AccountMetaData.logOut();
        }

    }
    componentDidMount = async () => {
        try {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
            window.socket = io(process.env.REACT_APP_API_URL, {
                transports: ['websocket'],
                reconnectionDelay: 5000
            });
        } catch (err) {

        }
    }

    activeTab = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let tabid = e.target.getAttribute("href") ? e.target.getAttribute("href").replace('#', '') : e.target.parentNode.getAttribute("href").replace('#', '');
        // this.setState({
        //     type: tabid
        // })
    }
    onExport = async ({ data, fileName, fileExtension, format_export }) => {
        fileName = fileName ? fileName : 'export-scan-' + moment().format('HH-mm-DD-MM-YYYY');
        fileExtension = fileExtension ? fileExtension : '.txt';
        fileName += fileExtension;

        this.setState({ loadingExport: true });

        let content = null;
        let fileType = 'text/plain;charset=utf-8';
        if (fileExtension == '.txt') {
            content = '';

            // console.log(data, format_export);

            data.forEach((item, i) => {
                if (format_export) {
                    content += this.getValueExport({
                        data: item,
                        format_export: format_export,
                        type: 'string'
                    });
                    if (i < data.length - 1) content += `\n`;
                } else {
                    content += this.getValueExport({
                        data: item,
                        type: 'string'
                    });
                    if (i < data.length - 1) content += `\n`;
                }
            })
        } else if (fileExtension == '.xlsx') {
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let data_excel = [];
            data.forEach((item, i) => {
                if (format_export) {
                    data_excel.push(this.getValueExport({
                        data: item,
                        format_export: format_export,
                        type: 'object'
                    }))
                }
            })
            const ws = XLSX.utils.json_to_sheet(data_excel);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            content = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        }

        var blob = new Blob([content], { type: fileType });
        setTimeout(() => {
            FileSaver.saveAs(blob, fileName);
            this.setState({ loadingExport: false });
        }, 300)
    };

    getValueExport = ({ data, format_export, type }) => {
        try {
            if (typeof data == 'string') {
                return data;
            } else {
                type = type ? type : 'object';
                let obj_export = {};
                let string_export = '';
                format_export.forEach((format, i) => {
                    let obj = '';
                    obj_export[`${this.formatExport[format]}`] = '';

                    if (format == 'location' && typeof data['location'] == 'object') {
                        obj = data['location'] ? `${data['location']['street'] || ''}, ${data['location']['city'] || ''}, ${data['location']['country'] || ''}, ${data['location']['zip'] || ''}` : ''
                    } else {
                        format.split('.').forEach((format_export) => {
                            obj = obj ? obj[format_export] : data[format_export];
                        })
                    }
                    if (Array.isArray(obj)) {
                        obj_export[`${this.formatExport[format]}`] = obj.join(', ');
                        string_export += obj.join(', ');
                    } else if (obj !== '' && obj !== undefined) {
                        obj_export[`${this.formatExport[format]}`] = obj;
                        string_export += obj;
                    }
                    if (i < format_export.length - 1) string_export += '|';
                })

                return type == 'object' ? obj_export : string_export;
            }
        } catch (err) {
            console.log(err);
        }

    }

    showUpgrade = async (type) => {
        try {
            type = type ? type : 'scan_uid';
            await this.props.getDetailUser();
            this.setState({
                visibleUpgradePackage: true,
                typeUpgradePackage: type
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Chi tiết đăng ký gói cước',
                type: 'error'
            })
        }
    }
    handleChecked = (e) => {
        try {
            let table = e.target.closest('table');
            let items = table.querySelectorAll('[name="check_item"]');
            let num_checked = 0;
            items.forEach((item) => {
                if (item.checked) num_checked++;
            })
            if (e.target.name == 'checkall') {
                if (items.length == num_checked) {
                    items.forEach((item) => {
                        item.checked = false;
                    })
                    num_checked = 0;
                } else {
                    items.forEach((item) => {
                        item.checked = true;
                    })
                    num_checked = items.length;
                }
            }
            if (items.length == num_checked) {
                table.querySelector('[name="checkall"]').checked = true;
            } else {
                table.querySelector('[name="checkall"]').checked = false;
            }
        } catch (err) {
            console.log(err);
        }
    }
    sleep = async (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms)
        })
    }
    copyText = (text) => {
        let el = document.querySelector('#input-copy');
        el.textContent = text;
        el.select();
        document.execCommand("copy");
        this.props.notification({
            title: 'Copy text',
            content: 'Thao tác thành công!',
            type: "success"
        });
    }

    render() {
        if (!this.props.user) return null;
        var props_m = {
            ...this.props,
            user: this.props.user,
            getPicture: getPicture,
            activeTab: this.activeTab,
            onExport: this.onExport,
            service: this.props.service,
            getDetailUser: this.props.getDetailUser,
            name_service: this.name_service,
            showUpgrade: this.showUpgrade,
            hideUpgrade: () => this.setState({ visibleUpgradePackage: false }),
            showDetailPayment: (show, transaction, create_payment, reload) => this.setState({ showPayment: show, detailBanking: transaction, createPayment: create_payment, reloadPayment: reload }),
            reloadPayment: this.state.reloadPayment,
            formatExport: this.formatExport,
            handleChecked: this.handleChecked,
            sleep: this.sleep,
            copyText: this.copyText,
            handleLoadingExport: (loading) => this.setState({ loadingExport: loading }),
            loadingExport: this.state.loadingExport
        }
        return (
            <div id="page-container" className="sidebar-o sidebar-inverse enable-page-overlay side-scroll page-header-fixed main-content-narrow side-trans-enabled">
                <Helmet>
                    <title>{window.location.host.indexOf('iseeding') > -1 ? 'VinhTool' : 'Smart target'} | Quét dữ liệu</title>
                </Helmet>

                <Header {...props_m} />
                <SiderBar {...props_m} />
                <UpgradePackage {...props_m} visible={this.state.visibleUpgradePackage} type={this.state.typeUpgradePackage} />
                <DetailPayment {...props_m} showPayment={this.state.showPayment} detailBanking={this.state.detailBanking}
                    hide={() => this.setState({ showPayment: false })} createPayment={this.state.createPayment}
                />

                <div id="fb-root"></div>
                <div className="fb-customerchat" attribution="setup_tool" page_id={process.env.REACT_APP_FANPAGE_SUPPORT_ID}></div>
                <textarea id="input-copy" style={{ opacity: 0, position: 'absolute', zIndex: 0, width: "1px", height: "1px", top: "-100px" }} onKeyPress={(e) => { e.preventDefault(); return false }} readOnly />

                <main id="main-container">
                    <Switch>
                        <Route
                            exact
                            path='/app/search-fanpage'
                            render={props => <HomeView {...props_m} {...props} type_app="search_fanpage" />}
                        />
                        <Route
                            exact
                            path='/app/interactive-fanpage'
                            render={props => <HomeView {...props_m} {...props} type_app="scan_interactive_fanpage" />}
                        />
                        <Route
                            exact
                            path='/app/member-group'
                            render={props => <HomeView {...props_m} {...props} type_app="scan_member_group" />}
                        />
                        <Route
                            exact
                            path='/app/interactive-group'
                            render={props => <HomeView {...props_m} {...props} type_app="scan_interactive_group" />}
                        />
                        {
                            this.props.user.is
                        }
                        {
                            this.props.user.is_scanphone ? 
                            <Route
                                exact
                                path='/app/scan-phone'
                                render={props => <HomeView {...props_m} {...props} type_app="scan_phone" />}
                            /> : null
                        }
                        <Route
                            exact
                            path='/app/interactive-post'
                            render={props => <HomeView {...props_m} {...props} type_app="scan_interactive_post" />}
                        />
                        <Route
                            exact
                            path='/app/scan-friends'
                            render={props => <HomeView {...props_m} {...props} type_app="scan_friends" />}
                        />
                        <Route
                            exact
                            path='/app/search-group'
                            render={props => <HomeView {...props_m} {...props} type_app="search_group" />}
                        />
                        <Route
                            exact
                            path='/app/me-likes'
                            render={props => <HomeView {...props_m} {...props} type_app="me_likes" />}
                        />
                        <Route
                            exact
                            path='/app/me-groups'
                            render={props => <HomeView {...props_m} {...props} type_app="me_groups" />}
                        />
                        <Route
                            exact
                            path='/app/detail-uid'
                            render={props => <HomeView {...props_m} {...props} type_app="info_uid" />}
                        />
                        <Route
                            exact
                            path='/app/detail-groupid'
                            render={props => <HomeView {...props_m} {...props} type_app="info_groupid" />}
                        />
                        <Route
                            exact
                            path='/app/detail-pageid'
                            render={props => <HomeView {...props_m} {...props} type_app="info_pageid" />}
                        />
                        <Route
                            exact
                            path='/app/scan-comment'
                            render={props => <HomeView {...props_m} {...props} type_app="scan_comment" />}
                        />
                        <Route
                            exact
                            path='/app/filter-files'
                            render={props => <FilterFileView {...props_m} {...props} onExport={this.onExport} />}
                        />
                        <Route
                            exact
                            path='/app/scan-comment-hidden'
                            render={props => <ScanCommentView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/app/scan-comment-hidden/:order_id'
                            render={props => <ScanDataCommentView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/app/findid'
                            render={props => <FindUidView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/support'
                            render={props => <GuidesView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/package'
                            render={props => <PackageView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/history'
                            render={props => <HistoryView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/me'
                            render={props => <MeView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/me/token'
                            render={props => <MeTokenView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/transaction'
                            render={props => <OwnerTransactionView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/admin/users'
                            render={props => <ManageUserView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/proxy'
                            render={props => <ProxyView {...props_m} {...props} />}
                        />

                        <Route
                            path='/app2'
                            render={props => <HomeView2 {...props_m} {...props} />}
                        />

                        <Route
                            path=""
                            render={props => <ResultView {...props_m} {...props} />}
                        />
                    </Switch>
                </main>
            </div>
        )
    }
}
