import React from "react";
import { ScanPhone } from '../component';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';

export default class ConvertPhone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        console.log('aadsasd');
        return (
            <div className="row flex-md-row" style={{ height: "100%" }}>
                <div className="col-md-4">
                    <div className="block block-rounded d-flex">
                        <div className="block-content d-flex">
                            <form className={`form-signin d-flex flex-column justify-content-between form-scan ${this.props.loading ? "form-loading" : ""}`} onSubmit={(e) => {
                                if (!this.props.loading) this.props.scanData();
                                e.preventDefault();
                                e.stopPropagation();
                            }} style={{ width: "100%" }}>

                                <ScanPhone />

                                <div className="">
                                    {
                                        this.props.loading ?
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.props.stopScan();
                                            }} style={{ width: "100%", height: "50px", fontSize: "18px", borderRadius: "5px" }} className="btn btn-danger">
                                                <Spin className="spin-m mr-10" /> Dừng Quét
                                                        </button>
                                            : <Button htmlType="submit" onClick={this.props.scanData} loading={false} style={{ width: "100%", height: "50px", fontSize: "18px", borderRadius: "5px" }} type="primary">
                                                Quét
                                                        </Button>
                                    }

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="block block-rounded">
                        <div className="block-content">
                            <div className="form-group">
                                <div className="block-header pr-0 pl-0 justify-content-between align-items-center">
                                    <h3 className="block-title" style={{ fontSize: "22px" }}>Thành công: <span className="text-success">{this.props.listData.length}</span></h3>
                                    <button type="button" className="btn btn-success btn-sm" onClick={() => this.setState({ visibleExport: true, dataExport: this.props.listData })}>
                                        <DownloadOutlined /> Tải xuống ({this.props.listData.length})
                                                                </button>
                                </div>
                                <textarea id="result" className="form-control" style={{ height: "260px" }} ></textarea>
                            </div>
                            <div className="form-group">
                                <div className="block-header pr-0 pl-0 justify-content-between align-items-center">
                                    <h3 className="block-title" style={{ fontSize: "22px" }}>Thất bại: <span className="text-danger">{this.props.listDataFalse.length}</span></h3>
                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => this.props.onExport({
                                        data: this.props.listDataFalse,
                                        fileExtension: '.txt',
                                        format_export: ['uid']
                                    })}>
                                        <DownloadOutlined /> Tải xuống ({this.props.listDataFalse.length})
                                                                </button>
                                </div>
                                <textarea id="result-false" className="form-control" style={{ height: "260px" }} ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
