import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { AccountMetaData, setCookie, dimens, getPicture } from '../../config/app';
import { fetchData, requestApiFb } from '../../lib/apis';

import {
    Header, SiderBar, SearchFanpage, MemberGroup, InterActive, ScanPhone, ExportData, InterActivePost, TableData,
    FilterData, Support, ScanFriend, SearchGroup, MeLike, MeGroup, DetailUid, FilterFile, ScanComment
} from '../../component';
import { Button, notification, Spin, Pagination, Upload} from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import io from 'socket.io-client';
import { type } from 'os';

export default class MainlayoutView extends Component {
    constructor(props) {
        super(props);
        if (!AccountMetaData.checkIssetLogin()) {
            // AccountMetaData.logIn();
        }
        this.state = {
            user: null,
            listData: [],
            token: "",
            loading: false,
            type: 'search_fanpage',
            listDataFalse: [],
            page: 1
        };
        this.idScan = null;
        this.formatExport = {
            "id": "Id",
            "avatar": "Avatar",
            "uid": "Id",
            "name": "Tên",
            "email": "Email",
            "phone": "Phone",
            "likes": "Số like",
            "link": "Link",
            "location": "Địa chỉ",
            "profile_picture.uri": "Avatar",
            "node.name": "Tên",
            "node.id": "Id",
            "node.profile_picture.uri": "Avatar",
            "type_phone": "Loại mạng",
            "emails": "Email",
            "count_like": "Lượt like",
            "count_comment": "Lượt comment",
            "count_reply": "Lượt reply",
            "count_share": "Lượt share",
            "gender": "Giới tính",
            "bio_text.text": "Giới thiệu",
            "mutual_friends.count": "Bạn chung",
            "friendship_status": "Kết bạn",
            "subscribe_status": "Theo dõi",
            "followers_count": "Theo dõi",
            "page_created_time": "Ngày tạo",
            "created_time": "Ngày tạo",
            "member_count": "Thành viên",
            "privacy": "Loại nhóm",
            "description": "Mô tả",
            "viewer_join_state": "Trạng thái join",
            "category": "Danh mục",
            "products": "Sản phẩm",
            "about" : "Giới thiệu",
            "like_time": "Ngày like",
            "birthday": "Ngày sinh",
            "locale": "Locale",
            "address": "Địa chỉ",
            "__typename": "Loại tài khoản",
            "email_in_content": "Email",
            "phone_in_content": "Phone",
            "content": "Nội dung",
            "friends_count": "Số bạn bè"
        }
        this.limit = 15;
        this.page = 1;
    }
    componentWillMount = async () => {
        try {
            await this.getDetail();
            await this.getInfo();

            this.setState({
                token: localStorage.getItem("token_scan")
            })
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: process.env.REACT_APP_FB_ID,
                    cookie: true,
                    xfbml: true,
                    version: 'v8.0'
                });
            }.bind(this);

            window.socket = io(process.env.REACT_APP_API_URL, {
                transports: ['websocket'],
                reconnectionDelay: 5000
            });
            window.socket.on('connect', data => {
                console.log('connect', window.socket.id);
                if (this.idScan) {
                    window.socket.emit('update-scan', {
                        _id: this.idScan,
                        update: {
                            "query.socket_id": window.socket.id
                        }
                    })
                }
                // window.socket.off("complete")
            })
            window.socket.on('start', data => {
                this.idScan = data._id;
            })

            window.socket.on('complete', response => {
                try {
                    let option = response.option;
                    let result = response.result;
                    if (response.orderId != this.idScan) {
                        console.log('change');
                        return
                    };

                    let listData = this.state.listData;
                    let listDataFalse = this.state.listDataFalse;
                    let limit = option.limit ? parseInt(option.limit) : false;
                    let data = result.data;
                    if (option.type == 'scan_interactive_group' || option.type == 'scan_interactive_fanpage') {
                        data = data.filter((item) => item.id != response.page_id);
                    }
                    data.forEach((item) => {
                        // console.log(item);
                        if (option.type != 'scan_phone') {
                            if (option.type == 'scan_interactive_group' || option.type == 'scan_interactive_fanpage' || option.type == 'scan_interactive_post') {
                                item['count_comment'] = item.type == 'comment' || item.type == 'reply' ? 1 : 0;
                                item['count_like'] = item.type == 'reaction' ? 1 : 0;
                                item['count_share'] = item.type == 'share' ? 1 : 0;
                                let find = listData.findIndex((item_i) => item_i.id == item.id);
                                if (find == -1) {
                                    item['actions'] = [
                                        {
                                            'type': item.type,
                                            'post_id': item.post_id
                                        }
                                    ]
                                    listData.push(item);
                                } else {
                                    if (item.type == 'comment' || item.type == 'reply') listData[find]['count_comment'] += 1;
                                    if (item.type == 'reaction') listData[find]['count_like'] += 1;
                                    if (item.type == 'share') listData[find]['count_share'] += 1;
                                    if (item.gender) listData[find]['gender'] = item.gender;
                                    listData[find]['actions'].push({
                                        'type': item.type,
                                        'post_id': item.post_id
                                    })
                                }
                            } else {
                                listData.push(item);
                            }
                        } else {
                            listData.push(item);
                        }
                    })
                    if (limit) {
                        listData = listData.splice(0, limit);
                    }
                    if (result.convert_false) {
                        let html = [];
                        result.convert_false.data.forEach((item) => {
                            listDataFalse.push(item);
                            html.push(`${item.uid}`)
                        })
                        if (html.length > 0 && document.querySelector('#result-false')) {
                            document.querySelector('#result-false').value += html.join('\n') + '\n';
                        }
                    }
                    if (listData.length > 0) {
                        let html = [];
                        for (let i = listData.length - 1; i >= 0; i--) {
                            let item = listData[i];
                            if (html.length <= 2000) {
                                if (option.type == 'scan_phone') {
                                    html.push(`${item.uid} | ${item.phone} | ${item.type_phone}`);
                                } else {
                                    html.push(`${item.id} | ${item.name}`);
                                }
                            } else {
                                break;
                            }
                        }
                        if (document.querySelector('#result')) document.querySelector('#result').value = html.join('\n') + '\n';
                    }
                    this.setState({ loading: !response.success, listData: listData, listDataFalse: listDataFalse });
                } catch (err) {
                    console.log(err);
                }
            })
            window.socket.on('result-error', result => {
                let error = result.error;
                if (this.state.type == result.option.type) {
                    this.setState({ loading: false }, () => {
                        notification['error']({
                            message: 'Quét dữ liệu',
                            description: error.error && error.error.message || 'Lỗi quét dữ liệu'
                        })
                    });
                }
            })
        } catch (err) {
            AccountMetaData.logOut();
        }

    }
    componentDidMount = async () => {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        this.stopScan();
    }
    getInfo = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await requestApiFb({
                    url: '/me?fields=about,name,picture{url},email',
                    method: 'get'
                });
                let user = this.state.user;
                user['display_name'] = response.name;
                return resolve(response);
            } catch (err) {

                return reject(err);
            }
        })
    }

    getDetail = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchData({
                    url: 'api/v1/detail',
                    method: 'get'
                });
                setCookie('usr', encodeURIComponent(JSON.stringify(response.data)), 1);
                this.setState({
                    user: response.data
                })
                return resolve(response.data);
            } catch (err) {
                return reject(err);
            }
        })
    }
    scanData = async (e) => {
        try {
            e.preventDefault();
            let token = document.querySelector('#inputToken');
            let limit = document.querySelector('#limit');
            let until = document.querySelector('[name="until"]');
            let since = document.querySelector('[name="since"]');
            let type_interactive = document.querySelectorAll('[name="type_interactive"]:checked');
            
            limit = limit && limit.value ? parseInt(limit.value) : false;

            let error = "";

            let data = {};
            if (limit) data['limit'] = limit;
            if(type_interactive.length == 0) type_interactive = document.querySelectorAll('[name="type_interactive"]');
            if(type_interactive.length > 0){
                let types = [];
                type_interactive.forEach((item) => {
                    types.push(item.value);
                })
                type_interactive = types;
            }
            if (this.state.type != 'scan_phone') {
                if (!token || !token.value) error = "Bạn phải nhập token";
                data['access_token'] = token.value;
                localStorage.setItem('token_scan', token.value);
            }
            if (this.state.type == 'search_fanpage' || this.state.type == 'search_group') {
                let keyword = document.querySelector('#inputKeyword').value;
                if (!keyword) error = "Bạn phải nhập từ khóa để quét";
                data['keyword'] = keyword;
            } else if (this.state.type == 'scan_interactive_fanpage') {
                let pageId = document.querySelector('#pageId').value;
                if (!pageId) error = "Bạn phải nhập Fanpage id để quét";
                data['page_id'] = pageId;
                data['type_interactive'] = type_interactive;
            } else if (this.state.type == 'scan_member_group') {
                let groupId = document.querySelector('#groupId').value;
                if (!groupId) error = "Bạn phải nhập Group id để quét";
                data['group_id'] = groupId;
            } else if (this.state.type == 'scan_interactive_group') {
                let groupId = document.querySelector('#groupId').value;
                if (!groupId) error = "Bạn phải nhập Group id để quét";
                data['page_id'] = groupId;
                data['type_interactive'] = type_interactive;
            } else if (this.state.type == 'scan_phone') {
                let inputUid = document.querySelector('#inputUid').value;
                inputUid = inputUid.split('\n').map((item) => item.split('|')[0].replace(/\s/g, ''));
                if (!inputUid) error = "Bạn phải nhập ít nhất 1 uid để quét";
                if (inputUid.length > 20000) error = 'Giới hạn 1 lần là 20k uid';
                data['uids'] = inputUid.join(',');
            } else if (this.state.type == 'scan_interactive_post') {
                let postId = document.querySelector('#postId').value;
                if (!postId) error = "Bạn phải nhập Id bài viết để quét";
                data['post_id'] = postId;
                data['type_interactive'] = type_interactive;
            } else if(this.state.type == 'scan_friends'){
                let uid = document.querySelector('#uId').value;
                if (!uid) error = "Bạn phải nhập Uid để quét";
                data['uid'] = uid;
            } else if (this.state.type == 'info_uid') {
                let inputUid = document.querySelector('#inputUid').value;
                inputUid = inputUid ? inputUid.split('\n').map((item) => item.split('|')[0].replace(/\s/g, '')) : [];
                if (inputUid.length > 20000 || inputUid.length <= 0) error = 'Giới hạn UID từ 1 -> 20k';
                data['uids'] = inputUid.join(',');
            } else if(this.state.type == 'scan_comment'){
                let postId = document.querySelector('#postId').value;
                if (!postId) error = "Bạn phải nhập Id bài viết để quét";
                data['post_id'] = postId;
            }

            if (until && until.value) data['until'] = Math.floor(parseInt(until.value) / 1000);
            if (since && since.value) data['since'] = Math.floor(parseInt(since.value) / 1000);

            if (error) {
                throw ({ message: error });
            }
            this.setState({ loading: true, listData: [], listDataFalse: [], page: 1 });

            this.timestamp = Date.now();
            if (document.querySelector('#result')) document.querySelector('#result').value = "";
            if (document.querySelector('#result-false')) document.querySelector('#result-false').value = ""
            window.socket.emit('start', {
                user_id: this.state.user._id,
                query: {
                    type: this.state.type,
                    timestamp: this.timestamp,
                    socket_id: window.socket.id,
                    ...data
                }
            })
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
            notification['error']({
                message: 'Quét dữ liệu',
                description: err.message || 'Lỗi quét fanpage'
            })
        }
    }
    requestApiFb = (url) => {
        return new Promise((resolve, reject) => {
            fetch(url).then(response => {
                return response.json();
            })
                .then((response) => {
                    if (response.error) reject(response.error);
                    resolve(response);
                }).catch((err) => {
                    reject(err)
                })
        })
    }
    onExport = async ({ data, fileName, fileExtension, format_export }) => {
        fileName = fileName ? fileName : 'export-scan-' + Date.now();
        fileExtension = fileExtension ? fileExtension : '.txt';
        fileName += fileExtension;

        this.setState({ loadingExport: true });

        let content = null;
        let fileType = 'text/plain;charset=utf-8';

        if (fileExtension == '.txt') {
            content = '';
            data.forEach((item, i) => {
                if (format_export) {
                    content += this.getValueExport({
                        data: item,
                        format_export: format_export,
                        type: 'string'
                    });
                    if (i < data.length - 1) content += `\n`;
                } else {
                    content += this.getValueExport({
                        data: item,
                        type: 'string'
                    });
                    if (i < data.length - 1) content += `\n`;
                }
            })
        } else if (fileExtension == '.xlsx') {
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let data_excel = [];
            data.forEach((item, i) => {
                if (format_export) {
                    data_excel.push(this.getValueExport({
                        data: item,
                        format_export: format_export,
                        type: 'object'
                    }))
                }
            })
            // console.log(data_excel);return
            const ws = XLSX.utils.json_to_sheet(data_excel);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            content = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        }

        var blob = new Blob([content], { type: fileType });
        setTimeout(() => {
            FileSaver.saveAs(blob, fileName);
            this.setState({ loadingExport: false });
        }, 300)
    };
    getValueExport = ({ data, format_export, type }) => {
        if(typeof data == 'string'){
            return data;
        } else {
            type = type ? type : 'object';

            let obj_export = {};
            let string_export = '';
            format_export.forEach((format, i) => {
                let obj = '';
                obj_export[`${this.formatExport[format]}`] = '';

                if (format == 'location') {
                    obj = data['location'] ? `${data['location']['street'] || ''}, ${data['location']['city'] || ''}, ${data['location']['country'] || ''}, ${data['location']['zip'] || ''}` : ''
                } else {
                    format.split('.').forEach((format_export) => {
                        obj = obj ? obj[format_export] : data[format_export];
                    })
                }
                if (Array.isArray(obj)) {
                    obj_export[`${this.formatExport[format]}`] = obj.join(', ');
                    string_export += obj.join(', ');
                } else if (obj !== '' && obj !== undefined) {
                    obj_export[`${this.formatExport[format]}`] = obj;
                    string_export += obj;
                }
                if (i < format_export.length - 1) string_export += '|';
            })

            return type == 'object' ? obj_export : string_export;
        }

    }
    activeTab = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let tabid = e.target.getAttribute("href") ? e.target.getAttribute("href").replace('#', '') : e.target.parentNode.getAttribute("href").replace('#', '');
        this.setState({
            type: tabid,
            loading: false,
            listData: [],
            listDataFalse: [],
            page: 1,
            queryFilter: null
        }, () => {
            this.stopScan();
            if (document.querySelector('#result')) document.querySelector('#result').value = "";
            if (document.querySelector('#result-false')) document.querySelector('#result-false').value = "";
        })
    }
    renderForm = (tabid) => {
        let result = null;
        switch (tabid) {
            case 'search_fanpage':
                result = <SearchFanpage key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })} />
                break;
            case 'scan_interactive_fanpage':
                result = <InterActive key={tabid} type="fanpage" saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })} />
                break;
            case 'scan_member_group':
                result = <MemberGroup key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })} />
                break;
            case 'scan_interactive_group':
                result = <InterActive key={tabid} type="group" saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })} />
                break;
            case 'scan_phone':
                result = <ScanPhone key={tabid} />
                break;
            case 'scan_interactive_post':
                result = <InterActivePost key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })} />
                break;
            case 'scan_friends':
                result = <ScanFriend key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })}/>
                break;
            case 'search_group':
                result = <SearchGroup key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })}/>
                break;
            case 'me_likes':
                result = <MeLike key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })}/>
                break;
            case 'me_groups':
                result = <MeGroup key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })}/>
                break;
            case 'info_uid': 
                result = <DetailUid key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })}/>
                break;
            case 'scan_comment':
                result = <ScanComment key={tabid} saveToken={(token) => this.setState({ token: token })} token={this.state.token} saveData={(data) => this.setState({ ...data })}/>
                break;
            default:
                break;
        }
        return result;
    }
    stopScan = (id) => {
        if (id || this.idScan) {
            window.socket.emit('update-scan', {
                _id: id || this.idScan,
                update: {
                    status: 3
                }
            })
        }
        this.idScan = null;
        this.setState({ loading: false });
    }
    render() {
        if (!this.state.user) return null;
        var props_m = {
            ...this.props,
            user: this.state.user,
            getPicture: getPicture,
            type: this.state.type,
            activeTab: this.activeTab
        }
        return (
            <div id="page-container" className="sidebar-o sidebar-inverse enable-page-overlay side-scroll page-header-fixed main-content-narrow side-trans-enabled">
                <Helmet>
                    <title>Quét Uid FB</title>
                </Helmet>

                <Header {...props_m} />
                <SiderBar {...props_m} />

                <ExportData visible={this.state.visibleExport} hide={() => this.setState({ visibleExport: false })} type={this.state.type}
                    onExport={this.onExport} data={this.state.dataExport || []} loading={this.state.loadingExport} formatExport={this.formatExport}
                />
                <FilterData visible={this.state.visibleFilter} hide={() => this.setState({ visibleFilter: false })} type={this.state.type}
                    saveFilter={(filter) => this.setState({ queryFilter: filter, page: 1}) } queryFilter={this.state.queryFilter}
                />

                <div id="fb-root"></div>
                <div className="fb-customerchat" attribution="setup_tool" page_id={process.env.REACT_APP_FANPAGE_SUPPORT_ID}></div>
                <main id="main-container">
                    { this.state.type == 'support' ? 
                        <Support /> :
                        this.state.type == 'filter_files' ?
                        <FilterFile onExport={this.onExport}/>
                        :
                        <div className="content" style={{ height: "100%", overflow: "auto" }}>
                            <div className="row flex-md-row" style={{ height: "100%" }}>
                                <div className="col-md-4">
                                    <div className="block block-rounded d-flex">
                                        <div className="block-content d-flex">
                                            <form className={`form-signin d-flex flex-column justify-content-between form-scan ${this.state.loading ? "form-loading" : ""}`} onSubmit={(e) => {
                                                if (!this.state.loading) this.scanData();
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }} style={{ width: "100%" }}>

                                                {this.renderForm(this.state.type)}

                                                <div className="">
                                                    {
                                                        this.state.loading ?
                                                            <button onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                this.stopScan();
                                                            }} style={{ width: "100%", height: "50px", fontSize: "18px", borderRadius: "5px" }} className="btn btn-danger">
                                                                <Spin className="spin-m mr-10" /> Dừng Quét
                                                        </button>
                                                            : <Button htmlType="submit" onClick={this.scanData} loading={false} style={{ width: "100%", height: "50px", fontSize: "18px", borderRadius: "5px" }} type="primary">
                                                                Quét
                                                        </Button>
                                                    }

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    {
                                        this.state.type == 'scan_phone' ?
                                            <div className="block block-rounded">
                                                <div className="block-content">
                                                    <div className="form-group">
                                                        <div className="block-header pr-0 pl-0 justify-content-between align-items-center">
                                                            <h3 className="block-title" style={{ fontSize: "22px" }}>Thành công: <span className="text-success">{this.state.listData.length}</span></h3>
                                                            <button type="button" className="btn btn-success btn-sm" onClick={() => this.setState({ visibleExport: true, dataExport: this.state.listData})}>
                                                                <DownloadOutlined /> Tải xuống ({this.state.listData.length})
                                                                </button>
                                                        </div>
                                                        <textarea id="result" className="form-control" style={{ height: "260px" }} ></textarea>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="block-header pr-0 pl-0 justify-content-between align-items-center">
                                                            <h3 className="block-title" style={{ fontSize: "22px" }}>Thất bại: <span className="text-danger">{this.state.listDataFalse.length}</span></h3>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => this.onExport({
                                                                data: this.state.listDataFalse,
                                                                fileExtension: '.txt',
                                                                format_export: ['uid']
                                                            })}>
                                                                <DownloadOutlined /> Tải xuống ({this.state.listDataFalse.length})
                                                                </button>
                                                        </div>
                                                        <textarea id="result-false" className="form-control" style={{ height: "260px" }} ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            : <TableData listData={this.state.listData} type={this.state.type} limit={this.limit} page={this.state.page}
                                                loading={this.state.loading} changePage={(current) => this.setState({ page: current })}
                                                visibleExport={(data) => this.setState({
                                                    visibleExport: true,
                                                    dataExport: data
                                                })}
                                                showFilter={() => this.setState({ visibleFilter: true })} queryFilter={this.state.queryFilter}
                                                listDataFalse={this.state.listDataFalse}
                                                onExport={this.onExport}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {/* <div className="footer">
                        <a href="https://ichot.vn" target="_blank" className="btn btn-lg btn-block text-uppercase"><i className="fab fa-google mr-2"></i> Created by Ichot.vn</a>
                    </div> */}
                </main>
            </div>
        )
    }
}
