import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Select, Form, Input } from 'antd';
import qs from 'qs';
import moment from 'moment';
import { FormOrder } from '../component';
import { fetchData } from '../lib/apis';
const { Option } = Select;

export default class FindUid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fb_id: null,
            listData: [],
            loadingData: false
        };
        this.limit = 50;
        this.page = 1;
    }
    componentWillMount = () => {

    }
    submitFind = async (values) => {
        try {
            this.setState({ loading: true, error: null });
            let response = await fetchData({
                url: `api/v1/findid?${qs.stringify(values)}`,
                method: 'get'
            });
            if (!response.fb_id) throw ({ message: 'Không thể convert được link' })
            this.setState({ loading: false, fb_id: response.fb_id });
        } catch (err) {
            this.setState({ loading: false, error: err.message || 'Không thể convert được link', fb_id: null });
        }
    }
    submitFindMutiple = async (values) => {
        try {
            this.setState({ loading: true });
            let links = values['links'].split('\n') || [];
            let listData = [];

            while (links.length > 0) {
                let links_splice = links.splice(0, 50);
                let promise = [];
                for (let i = 0; i < links_splice.length; i++) {
                    let item = links_splice[i].replace(/\s/g, '');
                    if (item) {
                        promise.push(new Promise(async (resolve, reject) => {
                            try {
                                let response = await fetchData({
                                    url: `api/v1/findid?link=${item}`,
                                    method: 'get'
                                });
                                resolve({
                                    link: item,
                                    id: response.fb_id || ""
                                });
                            } catch (err) {
                                resolve({
                                    link: item,
                                    id: ""
                                });
                            }
                        }))
                    }
                }
                listData = listData.concat(await Promise.all(promise));
                await this.props.sleep(1000);
            }

            this.setState({ loading: false, listData: listData });
            this.props.notification({
                type: "success",
                content: "Đã có kết quả",
                title: "Convert Link to UID"
            })

        } catch (err) {
            this.setState({ loading: false, error: err.message || 'Không thể convert được link', fb_id: null });
        }
    }
    render() {

        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Find UID</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="mb-20">
                            <Form
                                onFinish={this.submitFindMutiple}
                            >
                                <div className="alert alert-primary">
                                    <div>- Công cụ miễn phí giúp bạn dễ dàng lấy ID Facebook một cách dễ dàng</div>
                                    <div>- Nếu không tìm được ID hãy liên hệ ngay tới <a href={this.props.service.link_support} target="_blank" className="text-danger"><u>chúng tôi</u></a> để được hỗ trợ.</div>
                                    <div>- Mỗi dòng 1 link.</div>
                                </div>
                                {
                                    this.state.error ?
                                        <div className="alert alert-danger">{this.state.error}</div> : null
                                }
                                {
                                    this.state.fb_id ?
                                        <div className="alert alert-primary d-flex align-items-center" onClick={() => this.props.copyText(this.state.fb_id)}>FB ID: {this.state.fb_id}</div> : null
                                }
                                {/* <Form.Item name="link">
                                    <Input placeholder="Nhập link FB" style={{height: "40px"}}/>
                                </Form.Item> */}
                                <Form.Item name="links">
                                    <Input.TextArea placeholder="Vd: https://www.facebook.com/username" style={{ minHeight: "160px" }} />
                                </Form.Item>
                                <div className="d-flex">
                                    <Button htmlType="submit" type="primary" loading={this.state.loading}>Tìm ngay</Button>
                                    {
                                        this.state.listData.length > 0 ? <Button style={{marginLeft: "10PX"}} htmlType="button" type="ghost" onClick={() => this.props.onExport({
                                            data: this.state.listData,
                                            fileExtension: '.xlsx',
                                            format_export: ['link', 'id']
                                        })}>Download ({this.state.listData.length})</Button> : null
                                    }
                                </div>
                            </Form>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-vcenter table-text-center table-white-space">
                                <thead>
                                    <tr>
                                        <th className="text-left">#</th>
                                        <th>URl</th>
                                        <th>Kết quả</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                            return <tr key={i}>
                                                <td className="text-left">{i + 1}</td>
                                                <td>{item.link}</td>
                                                <td>{item.id}</td>
                                            </tr>
                                        }) : <tr>
                                            <td colSpan={11}>Chưa có dữ liệu</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
