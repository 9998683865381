import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {
    Header, SiderBar, SearchFanpage, MemberGroup, InterActive, ScanPhone, ExportData, InterActivePost, TableData,
    FilterData, Support, ScanFriend, SearchGroup, MeLike, MeGroup, DetailUid, FilterFile, ScanComment,
    DetailGroupId, DetailPageId, TopInteractive
} from '../component';
import { Button, notification, Spin, Pagination, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import io from 'socket.io-client';
import { fetchData, requestApiFb } from '../lib/apis';

export default class HomeView extends Component {
    constructor(props) {
        super(props);
        let user_scan = null;
        try {
            user_scan = JSON.parse(localStorage.getItem('user_scan'));
        } catch (err) {

        }
        this.state = {
            listData: [],
            token: "",
            loading: false,
            listDataFalse: [],
            page: 1,
            user_scan: user_scan,
            token: user_scan ? user_scan.access_token : '',
            type_app: this.props.type_app
        };
        this.idScan = null;
        this.formatExport = {
            "id": "Id",
            "uid": "UId",
            "name": "Tên",
            "email": "Email",
            "phone": "Phone",
            "likes": "Số like",
            "link": "Link",
            "location": "Địa chỉ",
            "profile_picture.uri": "Avatar",
            "node.name": "Tên",
            "node.id": "Id",
            "node.profile_picture.uri": "Avatar",
            "type_phone": "Loại mạng",
            "emails": "Email",
            "count_like": "Lượt like",
            "count_comment": "Lượt comment",
            "count_reply": "Lượt reply",
            "count_share": "Lượt share",
            "gender": "Giới tính",
            "bio_text.text": "Giới thiệu",
            "mutual_friends.count": "Bạn chung",
            "friendship_status": "Kết bạn",
            "subscribe_status": "Theo dõi",
            "followers_count": "Theo dõi",
            "page_created_time": "Ngày tạo",
            "created_time": "Ngày tạo",
            "member_count": "Thành viên",
            "privacy": "Loại nhóm",
            "description": "Mô tả",
            "viewer_join_state": "Trạng thái join",
            "category": "Danh mục",
            "products": "Sản phẩm",
            "about": "Giới thiệu",
            "like_time": "Ngày like",
            "birthday": "Ngày sinh",
            "locale": "Locale",
            "address": "Địa chỉ",
            "__typename": "Loại tài khoản",
            "email_in_content": "Email",
            "phone_in_content": "Phone",
            "content": "Nội dung",
            "viewer_post_status": "Kiểm duyệt",
            "friends_count": "Bạn bè",
            "avatar": "Avatar"
        }
        this.limit = 15;
        this.page = 1;
    }
    componentWillMount = async () => {
        try {
            window.socket.on('connect', data => {
                if (this.idScan) {
                    window.socket.emit('update-scan', {
                        _id: this.idScan,
                        update: {
                            "query.socket_id": window.socket.id
                        }
                    })
                }
            })
            window.socket.on('start', data => {
                this.idScan = data._id;
            })

            window.socket.on('complete', response => {
                try {
                    let option = response.option;
                    let result = response.result;
                    if (response.orderId != this.idScan) {
                        console.log('change');
                        return
                    };

                    let listData = [...this.state.listData];
                    let listDataFalse = [...this.state.listDataFalse];
                    let limit = option.limit ? parseInt(option.limit) : false;
                    let data = result.data;
                    if (option.type == 'scan_interactive_group' || option.type == 'scan_interactive_fanpage') {
                        data = data.filter((item) => item.id != response.page_id);
                    }
                    data.forEach((item) => {
                        // console.log(item);
                        if (option.type != 'scan_phone') {
                            if (option.type == 'scan_interactive_group' || option.type == 'scan_interactive_fanpage' || option.type == 'scan_interactive_post') {
                                item['count_comment'] = item.type == 'comment' || item.type == 'reply' ? 1 : 0;
                                item['count_like'] = item.type == 'reaction' ? 1 : 0;
                                item['count_share'] = item.type == 'share' ? 1 : 0;
                                let find = listData.findIndex((item_i) => item_i.id == item.id);
                                if (find == -1) {
                                    item['actions'] = [
                                        {
                                            'type': item.type,
                                            'post_id': item.post_id
                                        }
                                    ]
                                    listData.push(item);
                                } else {
                                    if (item.type == 'comment' || item.type == 'reply') listData[find]['count_comment'] += 1;
                                    if (item.type == 'reaction') listData[find]['count_like'] += 1;
                                    if (item.type == 'share') listData[find]['count_share'] += 1;
                                    if (item.gender) listData[find]['gender'] = item.gender;
                                    listData[find]['actions'].push({
                                        'type': item.type,
                                        'post_id': item.post_id
                                    })
                                }
                            } else {
                                listData.push(item);
                            }
                        } else {
                            listData.push(item);
                        }
                    })
                    if (limit) {
                        listData = listData.splice(0, limit);
                    }
                    if (result.convert_false) {
                        let html = [];
                        result.convert_false.data.forEach((item) => {
                            listDataFalse.push(item);
                            html.push(`${item.uid || item.id}`)
                        })
                        if (html.length > 0 && document.querySelector('#result-false')) {
                            document.querySelector('#result-false').value += html.join('\n') + '\n';
                        }
                    }
                    if (listData.length > 0) {
                        let html = [];
                        for (let i = listData.length - 1; i >= 0; i--) {
                            let item = listData[i];
                            if (html.length <= 2000) {
                                if (option.type == 'scan_phone') {
                                    html.push(`${item.uid} | ${item.phone} | ${item.type_phone}`);
                                } else {
                                    html.push(`${item.id} | ${item.name}`);
                                }
                            } else {
                                break;
                            }
                        }
                        if (document.querySelector('#result')) document.querySelector('#result').value = html.join('\n') + '\n';
                    }
                    console.log(listData);
                    this.setState({ loading: !response.success, listData: listData, listDataFalse: listDataFalse }, () => {
                        if (response.success) {
                            this.idScan = null;
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            })
            window.socket.on('result-error', result => {
                let error = result.error;
                if (this.state.type_app == result.option.type) {
                    this.setState({ loading: false }, () => {
                        notification['error']({
                            message: 'Smart target quét dữ liệu',
                            description: typeof error == 'object' ? error.message || error.error && error.error.message || 'Lỗi quét, vui lòng thử lại.' : 'Lỗi quét, vui lòng thử lại.'
                        })
                    });
                }
            })
        } catch (err) {

        }

    }
    componentDidMount = async () => {
        // this.stopScan();
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.location && nextProps.location.pathname != this.props.location.pathname) {
            this.setState({
                type_app: nextProps.type_app
            })
            this.stopScan('reset');
        }
    }
    scanData = async (e) => {
        try {
            e.preventDefault();

            this.setState({ loading: true, listData: [], listDataFalse: [], page: 1 });
            
            let data = await this.checkUsePackage(this.state.type_app);

            if (this.state.type_app != 'scan_phone') {
                await this.checkToken(data.access_token, data.cookie);
            }

            if (data['until']) data['until'] = Math.floor(parseInt(data['until']) / 1000);
            if (data['since']) data['since'] = Math.floor(parseInt(data['since']) / 1000);

            if (document.querySelector('#result')) document.querySelector('#result').value = "";
            if (document.querySelector('#result-false')) document.querySelector('#result-false').value = "";

            let res = await fetchData({
                url: `api/v2/scan/create`,
                method: 'post',
                data: JSON.stringify({
                    id_s: window.socket.id,
                    query: {
                        type: this.state.type_app,
                        timestamp: Date.now(),
                        ...data
                    }
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.idScan = res.data._id;
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
            notification['error']({
                message: 'Smart target quét dữ liệu',
                description: typeof err == 'string' ? err : err.message || 'Lỗi quét, vui lòng thử lại.',
                duration: 6,
                key: 'error_scan'
            })
        }
    }
    renderForm = (tabid) => {
        let result = null;
        // search_fanpage, scan_interactive_fanpage, scan_member_group
        // scan_interactive_group, scan_phone, scan_interactive_post, scan_friends, search_group
        // me_likes, me_groups, info_uid, scan_comment

        switch (tabid) {
            case 'search_fanpage':
                result = <SearchFanpage key={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'scan_interactive_fanpage':
                result = <InterActive key={tabid} type="fanpage" userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })} errorToken={this.state.errorToken} />
                break;
            case 'scan_member_group':
                result = <MemberGroup key={tabid} userScan={this.state.user_scan}  saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'scan_interactive_group':
                result = <InterActive key={tabid} userScan={this.state.user_scan} type="group" saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'scan_phone':
                result = <ScanPhone key={tabid} />
                break;
            case 'scan_interactive_post':
                result = <InterActivePost key={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'scan_friends':
                result = <ScanFriend key={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'search_group':
                result = <SearchGroup key={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'me_likes':
                result = <MeLike key={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'me_groups':
                result = <MeGroup key={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'info_uid':
                result = <DetailUid type={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'info_pageid':
                result = <DetailPageId type={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'info_groupid':
                result = <DetailGroupId type={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'scan_comment':
                result = <ScanComment key={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
                break;
            case 'scan_top_interactive':
                result = <TopInteractive key={tabid} userScan={this.state.user_scan} saveData={(data) => this.setState({ ...data })}
                    errorToken={this.state.errorToken}
                />
            default:
                break;
        }
        return result;
    }
    stopScan = (type) => {
        if (this.idScan) {
            window.socket.emit('update-scan', {
                _id: this.idScan,
                update: {
                    status: 2
                }
            })
        }
        this.idScan = null;
        if (type == 'reset') {
            this.setState({
                loading: false,
                listData: [],
                listDataFalse: [],
                page: 1,
                queryFilter: null
            })
        } else {
            this.setState({ loading: false });
        }

    }
    checkToken = async (token, cookie) => {
        try {
            if (!token) throw("Vui lòng nhập token!");
            this.setState({
                loadingCheckToken: true
            })
            let res = await fetchData({
                url: `api/v1/ufb/me`,
                method: 'post',
                data: JSON.stringify({
                    access_token: token,
                    cookie: cookie
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            this.setState({
                user_scan: {
                    ...res,
                    access_token: token,
                    cookie: cookie
                },
                loadingCheckToken: false,
                errorToken: null
            }, () => {
                localStorage.setItem('user_scan', JSON.stringify(this.state.user_scan));
            })
        } catch (err) {
            let message = typeof err == 'string' ? err : err.message || 'Lỗi kiểm tra token, vui lòng thử lại.';
            this.setState({
                loadingCheckToken: false,
                errorToken: `Error token: ${message}`,
                user_scan: null
            })
            throw(`Error token: ${message}`);
        }
    }
    checkUsePackage = (type_app) => {
        return new Promise(async (resolve, reject) => {
            try {
                let user = await this.props.getDetailUser();
                let user_package = user.package;
                if (user_package.end_time < Date.now()) {
                    return reject({ message: 'Gói cước bạn đã hết hạn, vui lòng nâng cấp để tiếp tục sử dụng!' })
                }
                let validates = {};
                if (type_app != 'scan_phone') {
                    validates['access_token'] = {
                        required: {
                            message: 'Bạn phải nhập token để tiếp tục.'
                        }
                    }
                    // if (this.state.errorToken) throw ({ message: 'Vui lòng nhập token mới!' })
                }
                switch (type_app) {
                    case 'scan_phone':
                        validates['uids'] = {
                            required: {
                                message: 'Bạn phải nhập UID để tiếp tục.'
                            },
                            min: {
                                value: 1,
                                message: 'Danh sách UID >= 1 và <= 50k'
                            },
                            max: {
                                value: 50000,
                                message: 'Danh sách UID >=1 và <= 50k'
                            }
                        }
                        break;
                    case 'search_fanpage':
                        validates['keyword'] = {
                            required: {
                                message: 'Bạn phải nhập từ khóa để tiếp tục.'
                            }
                        }
                        break;
                    case 'scan_interactive_fanpage':
                        validates['page_id'] = {
                            required: {
                                message: 'Bạn phải nhập ID Fanpage để tiếp tục.'
                            }
                        }
                        break;
                    case 'scan_interactive_group':
                        validates['page_id'] = {
                            required: {
                                message: 'Bạn phải nhập ID Group để tiếp tục.'
                            }
                        }
                        break;
                    case 'me_likes':
                        validates['uid'] = {
                            required: {
                                message: 'Bạn phải nhập UID để tiếp tục.'
                            }
                        }
                        break;
                    case 'me_groups':
                        validates['uids'] = {
                            required: {
                                message: 'Bạn phải nhập danh sách UID để tiếp tục.'
                            },
                            min: {
                                value: 1,
                                message: 'Danh sách UID >= 1 và <= 50k'
                            },
                            max: {
                                value: 50000,
                                message: 'Danh sách UID >=1 và <= 50k'
                            }
                        }
                        break;
                    case 'scan_member_group':
                        validates['group_id'] = {
                            required: {
                                message: 'Bạn phải nhập Group ID để tiếp tục.'
                            }
                        }
                        break;
                    case 'search_group':
                        validates['keyword'] = {
                            required: {
                                message: 'Bạn phải nhập từ khóa để tiếp tục.'
                            }
                        }
                        break;
                    case 'scan_interactive_post':
                        validates['post_id'] = {
                            required: {
                                message: 'Bạn phải nhập Id bài viết để tiếp tục.'
                            }
                        }
                        break;
                    case 'scan_comment':
                        validates['post_id'] = {
                            required: {
                                message: 'Bạn phải nhập Id bài viết để tiếp tục.'
                            }
                        }
                        break;
                    case 'scan_friends':
                        validates['uid'] = {
                            required: {
                                message: 'Bạn phải nhập UID để tiếp tục.'
                            }
                        }
                        break;
                    case 'info_uid':
                        validates['uids'] = {
                            required: {
                                message: 'Bạn phải nhập danh sách UID để tiếp tục.'
                            },
                            min: {
                                value: 1,
                                message: 'Danh sách UID >= 1 và <= 50k'
                            },
                            max: {
                                value: 50000,
                                message: 'Danh sách UID >=1 và <= 50k'
                            }
                        }
                        break;
                    case 'info_groupid':
                        validates['group_ids'] = {
                            required: {
                                message: 'Bạn phải nhập danh sách GROUP ID để tiếp tục.'
                            },
                            min: {
                                value: 1,
                                message: 'Danh sách UID >= 1 và <= 100k'
                            },
                            max: {
                                value: 100000,
                                message: 'Danh sách UID >=1 và <= 100k'
                            }
                        }
                        break;
                    case 'info_pageid':
                        validates['page_ids'] = {
                            required: {
                                message: 'Bạn phải nhập danh sách Fanpage ID để tiếp tục.'
                            },
                            min: {
                                value: 1,
                                message: 'Danh sách Fanpage Id >= 1 và <= 100k'
                            },
                            max: {
                                value: 100000,
                                message: 'Danh sách Fanpage Id >=1 và <= 100k'
                            }
                        }
                        break;
                    default:
                        break;
                }
                let values = await this.getValueForm(this.elFormScan, { validates });
                if (values['uids']) {
                    values['uids'] = values['uids'].replace(/\n/g, ',');
                }
                if (values['group_ids']) {
                    values['group_ids'] = values['group_ids'].replace(/\n/g, ',');
                }
                if (values['page_ids']) {
                    values['page_ids'] = values['page_ids'].replace(/\n/g, ',');
                }
                console.log(values);

                resolve(values);
            } catch (err) {
                reject(err);
            }
        })
    }
    getValueForm = (form, option) => {
        return new Promise((resolve, reject) => {
            try {
                let data = {};
                let { validates } = option;
                validates = validates ? validates : {};

                for (let i = 0; i < form.elements.length; i++) {
                    let element = form.elements[i];
                    if (element.name === "") {
                        continue;
                    }
                    if (validates[element.name]) {
                        if (validates[element.name]['required'] && !element.value) {
                            throw (validates[element.name]['required']['message'] || `${element.name} is required`);
                        }
                        if (validates[element.name]['min']) {
                            if (element.nodeName == 'TEXTAREA') {
                                let values = element.value.split('\n');
                                if (validates[element.name]['min']['value'] > parseInt(values.length)) {
                                    throw (validates[element.name]['min']['message'] || `${element.name} >= ${validates[element.name]['min']['value']}`);
                                }
                            } else {
                                if (validates[element.name]['min']['value'] > parseInt(element.value)) {
                                    throw (validates[element.name]['min']['message'] || `${element.name} >= ${validates[element.name]['min']['value']}`);
                                }
                            }
                        }
                        if (validates[element.name]['max']) {
                            if (element.nodeName == 'TEXTAREA') {
                                let values = element.value.split('\n');
                                if (parseInt(values.length) > validates[element.name]['max']['value']) {
                                    throw (validates[element.name]['max']['message'] || `${element.name} <= ${validates[element.name]['max']['value']}`);
                                }
                            } else {
                                if (parseInt(element.value) > validates[element.name]['max']['value']) {
                                    throw (validates[element.name]['max']['message'] || `${element.name} <= ${validates[element.name]['max']['value']}`);
                                }
                            }
                        }
                    }
                    switch (element.nodeName) {
                        case 'INPUT':
                            switch (element.type) {
                                case 'button':
                                case 'reset':
                                case 'submit':
                                case 'checkbox':
                                    if (element.checked) {
                                        if (data[element.name]) {
                                            data[element.name].push(element.value);
                                        } else {
                                            data[element.name] = [element.value];
                                        }
                                    }
                                    break;
                                case 'radio':
                                    if (element.checked) {
                                        data[element.name] = element.value;
                                    }
                                    break;
                                case 'number':
                                    data[element.name] = element.value ? parseInt(element.value) : undefined;
                                    break;
                                default:
                                    data[element.name] = element.value;
                                    break;
                            }
                            break;
                        case 'TEXTAREA':
                            data[element.name] = element.value;
                            break;
                        case 'SELECT':
                            switch (element.type) {
                                case 'select-one':
                                    data[element.name] = element.value;
                                    break;
                                case 'select-multiple':
                                    for (let j = element.options.length - 1; j >= 0; j = j - 1) {
                                        if (element.options[j].selected) {
                                            if (data[element.name]) {
                                                data[element.name].push(element.options[j].value);
                                            } else {
                                                data[element.name] = element.options[j].value;
                                            }
                                        }
                                    }
                                    break;
                            }
                            break;
                        case 'BUTTON':
                            break;
                    }
                }
                return resolve(data);
            } catch (err) {
                return reject(err);
            }
        })
    }
    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>

                <ExportData visible={this.state.visibleExport} hide={() => this.setState({ visibleExport: false })} type={this.state.type_app}
                    onExport={this.props.onExport} data={this.state.dataExport || []} loading={this.state.loadingExport} formatExport={this.formatExport}
                />

                <FilterData visible={this.state.visibleFilter} hide={() => this.setState({ visibleFilter: false })} type={this.state.type_app}
                    saveFilter={(filter) => this.setState({ queryFilter: filter, page: 1 })} queryFilter={this.state.queryFilter}
                />

                <div className="row flex-md-row" style={{ height: "100%" }}>
                    <div className="col-md-4">
                        <div className="block block-rounded d-flex">
                            <div className="block-content d-flex">
                                <form ref={(evt) => this.elFormScan = evt} className={`form-signin d-flex flex-column justify-content-between form-scan ${this.state.loading ? "form-loading" : ""}`} onSubmit={(e) => {
                                    if (!this.state.loading) this.scanData();
                                    e.preventDefault();
                                    e.stopPropagation();
                                }} style={{ width: "100%" }}>

                                    {this.renderForm(this.state.type_app)}

                                    <div className="">
                                        {
                                            this.state.loading ?
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.stopScan();
                                                }} style={{ width: "100%", height: "50px", fontSize: "18px", borderRadius: "5px" }} className="btn btn-danger">
                                                    <Spin className="spin-m mr-10" /> Dừng Quét
                                                </button>
                                                : <Button htmlType="submit" onClick={this.scanData} loading={false} style={{ width: "100%", height: "50px", fontSize: "18px", borderRadius: "5px" }} type="primary">
                                                    Quét
                                                </Button>
                                        }

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        {
                            this.state.type_app == 'scan_phone' ?
                                <div className="block block-rounded">
                                    <div className="block-content">
                                        <div className="form-group">
                                            <div className="block-header pr-0 pl-0 justify-content-between align-items-center">
                                                <h3 className="block-title" style={{ fontSize: "22px" }}>Thành công: <span className="text-success">{this.state.listData.length}</span></h3>
                                                <button type="button" className="btn btn-success btn-sm" onClick={() => this.setState({ visibleExport: true, dataExport: this.state.listData })}>
                                                    <DownloadOutlined /> Tải xuống ({this.state.listData.length})
                                                </button>
                                            </div>
                                            <textarea id="result" className="form-control" style={{ height: "260px" }} ></textarea>
                                        </div>
                                        <div className="form-group">
                                            <div className="block-header pr-0 pl-0 justify-content-between align-items-center">
                                                <h3 className="block-title" style={{ fontSize: "22px" }}>Thất bại: <span className="text-danger">{this.state.listDataFalse.length}</span></h3>
                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => this.props.onExport({
                                                    data: this.state.listDataFalse,
                                                    fileExtension: '.txt',
                                                    format_export: ['id']
                                                })}>
                                                    <DownloadOutlined /> Tải xuống ({this.state.listDataFalse.length})
                                                </button>
                                            </div>
                                            <textarea id="result-false" className="form-control" style={{ height: "260px" }} ></textarea>
                                        </div>
                                    </div>
                                </div>
                                : <TableData listData={this.state.listData} type={this.state.type_app} limit={this.limit} page={this.state.page}
                                    loading={this.state.loading} changePage={(current) => this.setState({ page: current })}
                                    visibleExport={(data) => this.setState({
                                        visibleExport: true,
                                        dataExport: data
                                    })}
                                    showFilter={() => this.setState({ visibleFilter: true })} queryFilter={this.state.queryFilter}
                                    listDataFalse={this.state.listDataFalse}
                                    onExport={this.props.onExport}
                                />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
