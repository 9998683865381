const settings = {}

export var dimens = {
    deviceWidth: window.innerWidth,
    domainSave: domainSaveCookie()
}

export const AccountMetaData = {
    token: (getCookie("token")) ? getToken(getCookie("token")) : '',
    logOut : () => {
        setCookie('token', '', 0);
        setCookie('usr', '', 0);
        setCookie('auth.token.facebook', '', 0);
        window.location.href = '/login';
        return true;
    },
    logIn: (option = {}) => {
        let { state } = option;
        window.location.href = `https://www.facebook.com/v8.0/dialog/oauth?client_id=${process.env.REACT_APP_FB_ID}&redirect_uri=${window.location.origin+'/login'}&state=${state}&scope=public_profile,email&response_type=token`;
    },
    getToken: () => {
        try {
            return getCookie("token");
        } catch {
            return '';
        }
    },
    setCookie: (name, value, days) => {
        var expires = "";
        if (days) {
        var date = new Date();
            date.setMonth(date.getMonth() + 1);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.domainSave+"; path=/";
    },
    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    checkIssetLogin: () => {
        if( !getCookie('token')  ) {
            return false;
        }
        try{
            dimens['token'] = getCookie("token");
            dimens['user'] = JSON.parse(decodeURIComponent(getCookie("usr")));
            dimens['auth.token.facebook']= 'Bearer '+getCookie("auth.token.facebook");
        } catch(err){
            console.log(err);
            return false;
        }
        return true;
    }
}

export const formatNumber = (nStr) => {
    nStr+='';let x=nStr.split(',');let x1=x[0];let x2=x.length>1?','+x[1]:'';let rgx=/(\d+)(\d{3})/;while(rgx.test(x1)){x1=x1.replace(rgx,'$1'+'.' + '$2');}return x1+x2;
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
        date.setMonth(date.getMonth() + 1);
        expires = "; expires=" + date.toUTCString();
    }
    // document.cookie = name + "=" + (value || "") + expires + ";domain=.muabanhay.com; path=/";
    document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.domainSave+"; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function checkIssetLogin(){
  if( !getCookie('token')  ) {
      return false;
  }
  try{
    dimens['token'] = atob(getCookie("token"));
  } catch(err){
      return false
  }
  return true;
}
export function getToken(cookie){
    try {
        return atob(cookie);
    } catch {
        return '';
    }
}
export function getPicture(params) {
    var { token, id, width, height, uid} = params;
    width = width ? width : 80;
    height = height ? height : 80;

    return `https://graph.facebook.com/${id || uid }/picture?height=${height}&width=${width}&access_token=6628568379|c1e620fa708a1d5696fb991c1bde5662`
}

export function domainSaveCookie(){
    try{
        let domain = "";
        let hostname = window.location.hostname;
        if(hostname.indexOf('.') > -1){
            let arr = hostname.split('.');
            domain = arr.splice(arr.length - 2, arr.length);
            domain = '.'+domain.join('.');
        } else {
            domain =  window.location.hostname;
        }
        console.log(hostname, domain);
        return domain;
    } catch(err){
        return "";
    }
}

export default settings