import React from "react";
import NumberFormat from 'react-number-format';
import { fetchData } from '../lib/apis';
import moment from 'moment';
import { Modal, Pagination} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default class Packages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            detailBanking: null
        };
        this.limit = 10;
        this.page = 1;
    }
    componentDidMount = () => {
        this.geData();
    }
    geData = async () => {
        try {
            let response = await fetchData({
                url: `api/v2/service/transaction?limit=${this.limit}&page=${this.page}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total
            })
        } catch (err) {

        }
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.reloadPayment && nextProps.reloadPayment != this.props.reloadPayment) {
            this.geData();
        }
    }
    createTransaction = async (e, item) => {
        if (item.is_default == 1) return;
        e.preventDefault();
        let _this = this;
        this.props.showDetailPayment(true, null, {
            title: 'Đăng ký gói cước',
            package_id: item.id
        })
        return
        Modal.confirm({
            title: 'Nâng cấp gói cước',
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v2/service/transaction`,
                            method: 'post',
                            data: JSON.stringify({
                                package_id: item.id
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });
                        // _this.setState({
                        //     detailBanking: res.data
                        // })
                        _this.props.showDetailPayment(res.data);
                        _this.geData();
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Nâng cấp gói cước',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="">
                    <div className="row">
                        {
                            this.props.service.packages.map((item, i) => {
                                if(item.is_default != 1){
                                    return <div className={`col-md-6 col-xl-3 ${item.is_default == 1 || (item.level < this.props.user.package.level && this.props.user.package.end_time > Date.now() ) ? 'register-default' : ''}`} key={i}>
                                        <a className="block block-link-pop block-rounded block-bordered text-center" href="javascript:void(0)">
                                            <div className="block-header">
                                                <h3 className="block-title" style={{ textTransform: "uppercase" }}>{item.name}</h3>
                                            </div>
                                            <div style={{ height: "300px" }}>
                                                <div className={`block-content ${item.is_vip || item.package_comment_hidden ? 'bg-danger' : item.is_default != 1 ? 'bg-primary' : 'bg-gray-lighter'}`}>
                                                    { item.package_comment_hidden ? 
                                                        <div className={`h1 font-w700 mb-10 text-white`}><NumberFormat value={item.packages[0]['price']} displayType={'text'} thousandSeparator={true} /> đ</div>
                                                        : <div className={`h1 font-w700 mb-10 ${item.is_vip || item.is_default != 1 ? 'text-white' : ''}`}><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /> đ</div>
                                                    }
                                                    {
                                                        item.package_comment_hidden ?
                                                        <div className={`h5 text-muted text-white`}>{item.packages[0]['time_text']}</div>
                                                        : <div className={`h5 text-muted ${item.is_vip || item.is_default != 1 ? 'text-white' : ''}`}>{item.time_text || item.time + ' ngày'}</div>
                                                    }
                                                </div>
                                                <div className="block-content">
                                                    {item.max_uid == -1 ? <p>Không giới hạn số lượt quét UID</p> : item.max_uid > 0 ? <p><NumberFormat value={item.max_uid} displayType={'text'} thousandSeparator={true} /> lượt quét UID</p> : null}
                                                    {/* {item.max_phone == -1 ? <p>Không giới hạn số lượt quét SDT</p> : item.max_phone > 0 ? <p><NumberFormat value={item.max_phone} displayType={'text'} thousandSeparator={true} /> lượt quét SDT</p> : null} */}
                                                    {item.max_thread && !item.package_comment_hidden ? <p>{item.max_thread} luồng</p> : ''}
                                                    {
                                                        item.package_comment_hidden ?
                                                        <p>Không bao gồm gói quét UID</p>
                                                        : null
                                                    }
                                                    { item.package_comment_hidden ? <p>Quét comment ẩn</p> : null}
                                                    { item.package_comment_hidden ? <p>Giới hạn {item['packages'][0]['max_thread']} post</p> : null}
                                                </div>
                                            </div>
                                            {
                                                item.is_default != 1 ?
                                                <div className="block-content block-content-full bg-gray-lighter">
                                                    {
                                                        this.props.user.package.comment_hidden && item.package_comment_hidden && this.props.user.package.comment_hidden.end_time > Date.now() ? 
                                                            <span className={`btn btn-hero btn-sm btn-rounded btn-noborder btn-alt-primary`} onClick={(e) => {
                                                                // this.props.showUpgrade();
                                                            }}>Đang sử dụng</span>
                                                        : item.package_comment_hidden ? 
                                                        <span className={`btn btn-hero btn-sm btn-rounded btn-noborder btn-danger`} onClick={() => {
                                                            this.props.showUpgrade('scan_comment_hidden')
                                                        }}>{'Đăng ký'}</span> 
                                                        : item.id == this.props.user.package.id && this.props.user.package.end_time > Date.now() ? 
                                                            <span className={`btn btn-hero btn-sm btn-rounded btn-noborder btn-alt-primary`} onClick={(e) => {
                                                                if(item.max_uid == -1 && item.max_phone == -1){
    
                                                                } else {
                                                                    this.props.showUpgrade();
                                                                }
                                                            }}>{item.max_uid == -1 && item.max_phone == -1 ? 'Đang sử dụng' : 'Nâng cấp'}</span>
                                                            : <span className={`btn btn-hero btn-sm btn-rounded btn-noborder ${item.is_vip ? 'btn-danger' : 'btn-primary'}`} onClick={(e) => this.createTransaction(e, item)}>Đăng ký</span> 
                                                        
                                                    }
                                                    
                                                </div> :
                                                <div className="block-content block-content-full bg-gray-lighter">
                                                    <span className={`btn btn-hero btn-sm btn-rounded btn-noborder btn-primary`}>
                                                        { item.id == this.props.user.package.id ? 'Đang sử dụng' : 'Gói miễn phí'}
                                                    </span>
                                                </div>
                                            }
                                        </a>
                                    </div>
                                }
                            })
                        }
                    </div>
                </div>
                <div className="block block-rounded mt-30" style={{ minHeight: "unset" }}>
                    <div className="block-header">
                        <h3 className="block-title">Lịch sử giao dịch</h3>
                        {
                            this.state.total > 0 ? 
                            <Pagination
                                total={this.state.total}
                                defaultCurrent={this.page}
                                showSizeChanger={false}
                                pageSize={this.limit}
                                current={this.page}
                                onChange={(current) => {
                                    this.page = current;
                                    this.geData();
                                }}
                                simple
                            /> : null
                        }
                    </div>
                    <div className="block-content">
                        <div className="table-responsive">
                            <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Mã</th>
                                        <th>Gói</th>
                                        <th>Số tiền</th>
                                        <th>Ngày tạo</th>
                                        <th>Ngày thanh toán</th>
                                        <th>Trạng thái</th>
                                        <th>Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                            return <tr key={i}>
                                                <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                <td>{item.code}</td>
                                                <td>{item.package.name}</td>
                                                <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /> đ</td>
                                                <td>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                                <td>{item.time_payment ? moment(item.time_payment).format('HH:mm DD-MM-YYYY') : ""}</td>
                                                <td>{item.status == 0 ? <span className="">Chờ thanh toán</span> : item.status == 1 ? <span className="text-success">Đã thanh toán</span> : <span className="text-danger">Bị hủy</span>}</td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        {item.status == 0 ? <a style={{ color: "#fff" }} className="btn btn-noborder btn-rounded btn-primary btn-sm"
                                                        onClick={() => this.props.showDetailPayment(true, item, null) }
                                                        >Thanh toán</a> : null}
                                                    </div>
                                                </td>
                                            </tr>
                                        }) : <tr>
                                                <td colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
