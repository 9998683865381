import React, { Component } from 'react';
import {Result, Button} from 'antd';

export default class ResultView extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(   
      <main id="main-container">
          <Result
            status="403"
            title={window.location.host.indexOf('iseeding') > -1 ? `Vinhtool` : 'Smart target'}
            subTitle="Công cụ hỗ trợ quét dữ liệu Facebook."
            extra={<Button type="primary" onClick={() => this.props.history.push('/app/search-fanpage')}>Sử dụng ngay</Button>}
        />
        </main>
    )
  }
}