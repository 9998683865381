import React from "react";
import { Popover } from 'antd';
import { AccountMetaData, dimens } from '../config/app';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount = () => {
        // document.querySelectorAll('[data-toggle="sidebar"]').forEach((anchor) => {
        //     anchor.addEventListener("click", function (e) {
        //         e.preventDefault();
        //         let action = this.getAttribute('data-action');
        //         console.log(action);
        //         if (action == 'sidebar_show') {
        //             document.querySelector('#page-container').classList.add('sidebar-o');
        //         } else {
        //             document.querySelector('#page-container').classList.remove('sidebar-o');
        //         }
        //     })
        // })
    }
    render() {
        return (
            <header id="page-header">
                <div className="content-header">
                    <div className="content-header-section">
                        <button type="button" className="btn btn-circle btn-dual-secondary btn-toggle-sidebar d-flex align-items-center justify-content-center" data-toggle="sidebar" data-action="sidebar_toggle">
                            <i className="fa fa-navicon" style={{ fontSize: "20px" }}></i>
                        </button>
                    </div>
                    <div className="content-header-section">
                        <Popover content={
                            <div className="" style={{ minWidth: "150px" }}>
                                <div className="header-acc-name" style={{ fontSize: "16px" }}>{this.props.user.display_name}</div>
                                <div className="dropdown-divider"></div>
                                {
                                    this.props.service.link_site_spam ? 
                                    <a href="#" onClick={(e) => {
                                        window.location.href = this.props.service.link_site_spam
                                    }} className="dropdown-item d-flex align-items-center"><i className="si si-layers mr-10"></i> Inbox Marketing</a> : null
                                }
                                <a href="/me" className="dropdown-item d-flex align-items-center"><i className="si si-user mr-10"></i> Cá nhân</a>
                                <a href="/package" className="dropdown-item d-flex align-items-center"><i className="si si-size-actual mr-10"></i> Gói cước</a>
                                <a className="dropdown-item d-flex align-items-center" onClick={() => AccountMetaData.logOut()}><i className="si si-logout mr-10"></i> Đăng xuất</a>
                            </div>
                        } trigger="click" placement="bottomRight">
                            <div className="btn-group align-items-center" role="group" style={{cursor: "pointer"}}>
                                <button type="button" className="btn btn-rounded" id="page-header-user-dropdown" style={{ display: "flex", alignItems: "center", paddingRight: "0px" }}>
                                    <a className="img-link mr-1" href="#">
                                        <img className="img-avatar" src={this.props.user.picture} alt="" style={{ width: "35px", height: "35px" }} />
                                    </a>
                                </button>
                                <div className="ml-10 d-flex flex-column justify-content-center info-name">
                                    <p className="mb-0">{this.props.user.display_name} <i className="fa fa fa-angle-down"></i></p>
                                </div>
                            </div>
                        </Popover>
                    </div>
                </div>

                <div id="page-header-search" className="overlay-header">
                    <div className="content-header content-header-fullrow">
                        <form action="be_pages_generic_search.html" method="post">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <button type="button" className="btn btn-secondary" data-toggle="layout" data-action="header_search_off">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                                <input type="text" className="form-control" placeholder="Search or hit ESC.." id="page-header-search-input" name="page-header-search-input" />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-secondary">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="page-header-loader" className="overlay-header bg-primary">
                    <div className="content-header content-header-fullrow text-center">
                        <div className="content-header-item">
                            <i className="fa fa-sun-o fa-spin text-white"></i>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
