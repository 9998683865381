import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Select, Form, Input } from 'antd';
import qs from 'qs';
import moment from 'moment';
import { FormOrder } from '../component';
import { fetchData } from '../lib/apis';
const { Option } = Select;

export default class MeToken extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0
        };
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getData();
    }
    getData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') this.page = 1;
            let query = {
                limit: this.limit,
                page: this.page
            }
            if (this.formFilter) {
                query = {
                    ...query,
                    ...this.formFilter.getFieldsValue()
                }
            }
            let response = await fetchData({
                url: `api/v2/scan/token/list?${qs.stringify(query)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false })
        }
    }
    delete = async (option = {}) => {
        let _this = this;
        let { item, type } = option;
        let ids = [];
        if (type == 'delete_manny') {
            let items_checked = document.querySelectorAll('[name="check_item"]:checked');
            if (items_checked.length == 0) {
                _this.props.notification({
                    content: 'Bạn phải chọn ít nhất 1 token!',
                    title: 'Xóa token',
                    type: 'error'
                })
                return
            }
            items_checked.forEach((item) => {
                ids.push(item.value);
            })
        } else {
            ids = [item._id];
        }
        Modal.confirm({
            title: 'Xóa token',
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v2/scan/token`,
                            method: 'delete',
                            data: JSON.stringify({
                                ids: ids
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });
                        _this.getData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa token',
                            type: 'success',
                            key: 'delete'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Thao tác thất bại',
                            title: 'Xóa token',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    saveToken = async (values) => {
        try {
            let tokens = values['tokens'] ? values['tokens'].split('\n') : [];
            if (tokens.length == 0) throw ({ message: 'Bạn phải nhập ít nhất 1 token!' });
            this.setState({
                loading_save_token: true
            })
            let success = 0;
            while (tokens.length > 0) {
                let token_check = tokens.splice(0, 10);
                let promise = [];
                for (let i = 0; i < token_check.length; i++) {
                    promise.push(new Promise(async (resolve, reject) => {
                        try {
                            let arr = token_check[i].split("|");
                            let access_token = arr[0] && arr[0].replace(/\s/gi, "");
                            let cookie = arr[1] && arr[1].replace(/\s/gi, "");
                            let response = await fetchData({
                                url: `api/v1/ufb/me`,
                                method: 'post',
                                data: JSON.stringify({
                                    access_token: access_token,
                                    cookie: cookie
                                }),
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                            if(response.id){
                                await fetchData({
                                    url: `api/v2/scan/token`,
                                    method: 'post',
                                    data: JSON.stringify({
                                        uid: response.id,
                                        name: response.name,
                                        access_token: access_token,
                                        status: 1,
                                        cookie: cookie
                                    }),
                                    headers: {
                                        "Content-Type": "application/json"
                                    }
                                });
                                success++;
                            }
                            resolve();
                        } catch (err) {
                            resolve();
                         }
                    }))
                }
                await Promise.all(promise);
                this.props.sleep(1000);
            }
            this.setState({
                loading_save_token: false
            })
            this.props.notification({
                content: `Cập nhập thành công ${success} token`,
                title: 'Cập nhập token scan',
                type: 'success'
            })
            this.getData('reset');
        } catch (err) {
            this.setState({
                loading_save_token: false
            })
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Cập nhập token scan',
                type: 'error'
            })
        }
    }
    checkToken= async (option = {}) => {
        try{
            let _this = this;
            let { item, type } = option;
            let ids = [];
            if (type == 'check_mutiple') {
                let items_checked = document.querySelectorAll('[name="check_item"]:checked');
                if (items_checked.length == 0) {
                    _this.props.notification({
                        content: 'Bạn phải chọn ít nhất 1 token!',
                        title: 'Check token',
                        type: 'error'
                    })
                    return
                }
                items_checked.forEach((item) => {
                    ids.push(item.value);
                })
            } else {
                ids = [item._id];
            }
            let listData = this.state.listData;
            let tokens = listData.filter((item) => ids.indexOf(item._id) > -1);
            if(tokens.length == 0) return;
            this.setState({ loading: true });

            let nick_success = 0;
            let nick_die = 0;
            
            while (tokens.length > 0) {
                let token_check = tokens.splice(0, 10);
                let promise = [];
                for (let i = 0; i < token_check.length; i++) {
                    let item = token_check[i];
                    promise.push(new Promise(async (resolve, reject) => {
                        try {
                            let status = 1;
                            try{
                                await fetchData({
                                    url: `api/v1/ufb/me`,
                                    method: 'post',
                                    data: JSON.stringify({
                                        access_token: item.access_token,
                                        cookie: item.cookie
                                    }),
                                    headers: {
                                        "Content-Type": "application/json"
                                    }
                                })
                                nick_success++;
                            } catch(err){
                                status = 2;
                                nick_die++;
                            }
                            await fetchData({
                                url: `api/v2/scan/token`,
                                method: 'post',
                                data: JSON.stringify({
                                    uid: item['uid'],
                                    status: status
                                }),
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            });
                            let findIndex = listData.findIndex((data) => data._id == item._id);
                            if(findIndex > -1){
                                listData[findIndex]['status'] = status;
                            }
                            resolve();
                        } catch (err) {
                            reject();
                        }
                    }))
                }
                await Promise.all(promise);
                this.props.sleep(1000);
            }
            this.setState({ loading: false, listData: listData });
            this.props.notification({
                content: `${nick_success} nick hoạt động, ${nick_die} nick die`,
                title: 'Check token',
                type: 'success'
            })
        } catch(err){
            this.setState({ loading: false });
            this.props.notification({
                content: typeof err == 'object' && err.message || 'Thao tác thất bại',
                title: 'Check token',
                type: 'error'
            })
        }
    }
    render() {
        let { listData } = this.state;

        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Quản lý token</h3>
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="mb-20">
                            <Form
                                onFinish={this.saveToken}
                            >
                                <div className="alert alert-primary">
                                    <div>- Token dùng để quét dữ liệu comment ẩn</div>
                                    <div>- Lưu ý đối với quét Group, token phải thuộc thành viên group mới quét được</div>
                                    <div>- Nên nhập 10 token trở lên để hỗ trợ quét nhiều luồng, nhanh và hạn chế die token</div>
                                    <div>- Bắt buộc phải nhập cả cookie của token nick: (mỗi nick 1 dòng)</div>
                                </div>
                                <Form.Item name="tokens">
                                    <Input.TextArea style={{ height: "150px" }} placeholder="Cấu trúc: token|cookie" />
                                </Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading_save_token}>Lưu ngay</Button>
                            </Form>
                        </div>
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Check hoạt động">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.checkToken({ type: 'check_mutiple'})}>
                                        <i className="far fa-shield-check"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Xóa nhiều">
                                    <button type="button" className={`btn btn-danger btn-sm d-flex align-items-center ml-5`} onClick={() => this.delete({ type: 'delete_manny' })}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: ""
                            }}
                            onChange={(e) => {
                                if (e.target.nodeName == 'INPUT') {
                                    if (this.timeout) clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        this.getData('reset');
                                    }, 300)
                                } else {
                                    this.getData('reset');
                                }
                            }}
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row tool-filter">
                                <div className="col-md-2 col-lg-2 pl-15 pr-5">
                                    <Form.Item name="name">
                                        <Input placeholder="Tên người dùng" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-15 pr-5">
                                    <Form.Item name="uid">
                                        <Input placeholder="UID" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => {
                                            this.getData('reset');
                                        }}>
                                            <Option value="">Trạng thái</Option>
                                            <Option value={1}>Hoạt động</Option>
                                            <Option value={2}>Không hoạt động</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Xóa lọc">
                                        <Button className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getData();
                                        }}></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Spin tip="loading.." spinning={this.state.loading}>
                                <table className="table table-striped table-vcenter table-text-center table-white-space">
                                    <thead>
                                        <tr>
                                            <th>
                                                <label className="css-control css-control-primary css-checkbox p-0">
                                                    <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}
                                                    />
                                                    <span className="css-control-indicator"></span>
                                                </label>
                                            </th>
                                            <th>#</th>
                                            <th>Avatar</th>
                                            <th>UID</th>
                                            <th>Tên người dùng</th>
                                            <th>Trạng thái</th>
                                            <th>Ngày tạo</th>
                                            <th className="text-right">Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listData.length > 0 ? listData.map((item, i) => {
                                                return <tr key={item._id}>
                                                    <td>
                                                        <label className="css-control css-control-primary css-checkbox p-0">
                                                            <input name="check_item" type="checkbox" className="css-control-input checkbox" value={item._id}
                                                                onChange={this.props.handleChecked}
                                                            />
                                                            <span className="css-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                    <td>
                                                        <a className="img-link mr-1" href={`https://fb.com/${item.uid}`} target="_blank">
                                                            <img className="img-avatar" src={this.props.getPicture(item)} alt="" style={{ width: "35px", height: "35px" }} 
                                                                onError={(e) => e.target.src = '/media/avatars/avatar0.jpg'}
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>{item.uid}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        {item.status == 1 ? <span className="badge badge-primary">Hoạt động</span> : <span className="badge badge-danger">Không hoạt động</span>}
                                                    </td>
                                                    <td>{moment(item.time_created).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <Tooltip title="Trang cá nhân">
                                                                <a href={`https://fb.com/${item.uid}`} target="_blank" className="btn btn-sm btn-primary text-white mr-5">
                                                                    <i className="fa fa-facebook-f"></i>
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip title="Check hoạt động">
                                                                <a href="#" className="btn btn-sm btn-primary text-white mr-5" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.checkToken({ item: item })
                                                                }}>
                                                                    <i className="far fa-shield-check"></i>
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip title="Copy token">
                                                                <a href="#" className="btn btn-sm btn-danger mr-5" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.props.copyText(item.access_token);
                                                                }}>
                                                                    <i className="fa fa-copy"></i>
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip title="Xóa token">
                                                                <a href="#" className="btn btn-sm btn-danger" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.delete({ item: item })
                                                                }}>
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                    <td colSpan={11}>Chưa có dữ liệu</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Pagination
                                total={listData.length || 1}
                                defaultCurrent={1}
                                showSizeChanger={true}
                                pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                pageSize={this.limit}
                                current={this.page}
                                onChange={(current) => {
                                    this.page = current;
                                    this.getData();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
