import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class uploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: []
        }
        this.form = null;
    }
    onFileChange = ({ fileList }) => {
        this.setState({ fileList: [], file: fileList[0] });
    }
    render() {
        const { fileList } = this.state;
        return (
            <Modal
                title="Upload"
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
            >
                <Form
                    onFinish={this.props.onFinish}
                    ref={(evt) => this.form = evt}
                    initialValues={{
                        "format": this.props.format,
                        "format_text": this.props.format.join(' | ')
                    }}
                >
                    <Form.Item name="upload" >
                        <Upload.Dragger name="files" beforeUpload={() => false} accept={'.txt'} fileList={fileList} onChange={this.onFileChange} showUploadList={false}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">{this.state.file ? this.state.file.name : 'Support for a single or bulk upload.'}</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <Form.Item name="format" hidden={!this.props.select_format}>
                        <Checkbox.Group style={{ width: "100%" }} onChange={(e) => { this.form.setFieldsValue({ format_text: e.join(' | ') }) }}>
                            <Row>
                                {
                                    this.props.format.map((item, i) => {
                                        return <Col key={i} span={8}>
                                            <Checkbox value={item} style={{ lineHeight: '32px' }}>
                                                {item}
                                            </Checkbox>
                                        </Col>
                                    })
                                }

                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item
                        name="format_text"
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <div className="text-center">
                        <Button type="primary" loading={this.props.loading} htmlType="submit" disabled={!this.state.file ? true : false}>
                            Tiếp tục
                            </Button>
                    </div>
                </Form>
            </Modal>
        )
    }
}
