import React from "react";
import { Button, notification, Spin, Pagination } from 'antd';
import { DownloadOutlined, FilterFilled} from '@ant-design/icons';

import moment from 'moment';

export default class TableData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        let { type, listData, limit, page, queryFilter, listDataFalse} = this.props;

        listData = [...listData];
        listDataFalse = listDataFalse ? [...listDataFalse] : [];
        let table = null;
        limit = limit ? limit : 15;
        page = page ? page : 1;
        let skip = (page - 1) * limit;

        // console.log(queryFilter);
        if(queryFilter){
            if(queryFilter['sort_type'] && queryFilter['sort_name']){
                if(queryFilter['sort_name'].indexOf('detail.') > -1){
                    if(queryFilter['sort_type'] == 1){
                        listData.sort((a, b) => a['detail'][queryFilter['sort_name'].split('.')[1]] - b['detail'][queryFilter['sort_name'].split('.')[1]]);
                    } else {
                        listData.sort((a, b) => b['detail'][queryFilter['sort_name'].split('.')[1]] - a['detail'][queryFilter['sort_name'].split('.')[1]] );
                    }
                } else if(queryFilter['sort_name'] == 'mutual_friends'){
                    if(queryFilter['sort_type'] == 1){
                        listData.sort((a, b) => a['mutual_friends']['count'] - b['mutual_friends']['count']);
                    } else {
                        listData.sort((a, b) => b['mutual_friends']['count'] - a['mutual_friends']['count'] );
                    }
                } else {
                    if(queryFilter['sort_type'] == 1){
                        listData.sort((a, b) => a[queryFilter['sort_name']] - b[queryFilter['sort_name']]);
                    } else {
                        listData.sort((a, b) => b[queryFilter['sort_name']] - a[queryFilter['sort_name']] );
                    }
                }
            }
            if(queryFilter['count_like']){
                listData = listData.filter((item) => item.count_like >= parseInt(queryFilter['count_like']))
            }
            if(queryFilter['count_comment']){
                listData = listData.filter((item) => item.count_comment >= parseInt(queryFilter['count_comment']))
            }
            if(queryFilter['count_share']){
                listData = listData.filter((item) => item.count_share >= parseInt(queryFilter['count_share']))
            }
            if(queryFilter['likes']){
                listData = listData.filter((item) => item['likes'] >= parseInt(queryFilter['likes']))
            }
            if(queryFilter['mutual_friends']){
                listData = listData.filter((item) => item['mutual_friends']['count'] >= parseInt(queryFilter['mutual_friends']))
            }
            if(queryFilter['followers_count']){
                listData = listData.filter((item) => item['followers_count'] >= parseInt(queryFilter['followers_count']))
            }
            if(queryFilter['member_count']){
                listData = listData.filter((item) => item['member_count'] >= parseInt(queryFilter['member_count']))
            }
            if(queryFilter['type_group']){
                listData = listData.filter((item) => item['privacy'] == queryFilter['type_group'])
            }
            if(queryFilter['gender']){
                listData = listData.filter((item) => item['gender'].toLocaleLowerCase() == queryFilter['gender'])
            }
            if(queryFilter['__typename']){
                listData = listData.filter((item) => item['__typename'] == queryFilter['__typename'])
            }
            if(queryFilter['country']){
                listData = listData.filter((item) => item['location'] && item['location']['country'] == queryFilter['country'])
            }
            if(queryFilter['uid']){
                listData = listData.filter((item) => item['uid'] == queryFilter['uid'])
            }
            if(queryFilter['location']){
                listData = listData.filter((item) => item['location'] && item['location'].toLowerCase() == queryFilter['location'].toLowerCase())
            }
            if(queryFilter['viewer_post_status']){
                listData = listData.filter((item) => item['viewer_post_status'] == queryFilter['viewer_post_status']);
            }

            if(typeof queryFilter['member_count_min'] == 'number'){
                listData = listData.filter((item) => item['member_count'] >= parseInt(queryFilter['member_count_min']))
            }

            if(typeof queryFilter['member_count_max'] == 'number'){
                listData = listData.filter((item) => item['member_count'] <= parseInt(queryFilter['member_count_max']))
            }
            
        }

        let data = listData.slice(skip, (limit * page));

        if (type == 'search_fanpage') {
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Số like</th>
                        <th>Theo dõi</th>
                        <th>Địa chỉ</th>
                        <th>Ngày tạo</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            console.log(item.likes);
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.emails ? item.emails.join(', ') : ''}</td>
                                <td>{item.phone}</td>
                                <td>{typeof item.likes == "object" ? item.likes.data.length : item.likes}</td>
                                <td>{item.followers_count}</td>
                                <td>
                                    {item.location ? `${item.location.street || item.location.city || ''}` : ''}
                                </td>
                                <th>{moment(item.page_created_time).format('HH:mm DD-MM-YYYY')}</th>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if (type == 'scan_interactive_fanpage' || type == 'scan_interactive_group' || type == 'scan_interactive_post') {
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Tên</th>
                        {/* { type != 'scan_interactive_group' ? <th>Loại user</th> : ''} */}
                        <th>like</th>
                        <th>Comment</th>
                        { type != 'scan_interactive_group' ? <th>Share</th> : ''}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                {/* { type != 'scan_interactive_group' ? <td>{item.__typename}</td> : ''} */}
                                <td>{item.count_like}</td>
                                <td>{item.count_comment}</td>
                                {type != 'scan_interactive_group' ? <td>{item.count_share}</td> : ''}
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if (type == 'scan_member_group') {
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        {/* <th>Ảnh</th> */}
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Giới thiệu</th>
                        <th>Kết bạn</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                {/* <td>
                                    <a target="_blank" href={`https://fb.com/${item.id}`}><img src={item.profile_picture.uri} style={{ width: "35px", borderRadius: "50%" }} /></a>
                                </td> */}
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.bio_text ? item.bio_text.text : ''}</td>
                                <td>{item.friendship_status}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if(type == 'scan_friends'){
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        {/* <th>Ảnh</th> */}
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Bạn chung</th>
                        <th>Kết bạn</th>
                        <th>Theo dõi</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.mutual_friends ? item.mutual_friends.count : 0}</td>
                                <td>{item.friendship_status}</td>
                                <td>{item.subscribe_status}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if(type == 'search_group'){
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Số thành viên</th>
                        <th>Loại nhóm</th>
                        <th>Trạng thái join</th>
                        <th>Ngày tạo</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.member_count}</td>
                                <td>{item.privacy}</td>
                                <td>{item.viewer_join_state}</td>
                                <th>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</th>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if(type == 'me_likes'){
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Số like</th>
                        <th>Theo dõi</th>
                        <th>Địa chỉ</th>
                        <th>Ngày like</th>
                        <th>Ngày tạo</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.emails ? item.emails.join(', ') : ''}</td>
                                <td>{item.phone}</td>
                                <td>{item.likes}</td>
                                <td>{item.followers_count}</td>
                                <td>{item.location ? item.location.street || item.location.city || "" : ""}</td>
                                <th>{moment(item.like_time).format('HH:mm DD-MM-YYYY')}</th>
                                <th>{moment(item.page_created_time).format('HH:mm DD-MM-YYYY')}</th>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if(type == 'me_groups'){
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>UID</th>
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Số thành viên</th>
                        <th>Loại nhóm</th>
                        <th>Kiểm duyệt</th>
                        <th>Vị trí</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.uid}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.member_count}</td>
                                <td>{item.privacy}</td>
                                <td>{item.viewer_post_status}</td>
                                <td>{item.location}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if(type == 'info_uid'){
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Bạn bè</th>
                        <th>Giới tính</th>
                        <th>Ngày sinh</th>
                        <th>Email</th>
                        <th>Locale</th>
                        <th>Địa chỉ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.friends_count}</td>
                                <td>{item.gender}</td>
                                <td>{item.birthday}</td>
                                <td>{item.email}</td>
                                <td>{item.locale}</td>
                                <td>{item.address}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if(type == 'scan_comment'){
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Giới tính</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Content</th>
                        <th>Thời gian</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.gender}</td>
                                <td>{item.email_in_content}</td>
                                <td>{item.phone_in_content}</td>
                                <td>
                                    <textarea className="form-control">{item.content}</textarea>
                                </td>
                                <th>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</th>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if(type == 'info_groupid'){
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Số thành viên</th>
                        <th>Email</th>
                        <th>Loại nhóm</th>
                        <th>Kiểm duyệt</th>
                        <th>Ngày tạo</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.member_count}</td>
                                <td>{item.email}</td>
                                <td>{item.privacy}</td>
                                <td>{item.viewer_post_status}</td>
                                <th>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</th>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        } else if(type == 'info_pageid'){
            table = <table className="table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Tên</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Số like</th>
                        <th>Theo dõi</th>
                        <th>Địa chỉ</th>
                        <th>Ngày tạo</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, i) => {
                            let stt = page > 1 ? ((limit * page) - limit) + i + 1 : i + 1;
                            return <tr key={stt}>
                                <td>{stt}</td>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.emails ? item.emails.join(', ') : ''}</td>
                                <td>{item.phone}</td>
                                <td>{item.likes}</td>
                                <td>{item.followers_count}</td>
                                <td>{item.location ? item.location.street || item.location.city || "" : ""}</td>
                                <th>{moment(item.page_created_time).format('HH:mm DD-MM-YYYY')}</th>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        }
        return (
            <div className="block block-rounded mb-0">
                <div className="block-header pb-0 justify-content-between align-items-center">
                    <h3 className="block-title">Kết quả: <span className="text-success">{listData.length}</span></h3>
                    <div className={`text-right ${this.props.loading ? 'data-loading' : ''}`}>
                        {
                            listData.length > 0 ? <Pagination
                                total={listData.length}
                                defaultCurrent={1}
                                showSizeChanger={false}
                                pageSize={limit}
                                current={page}
                                onChange={(current) => this.props.changePage(current)}
                                simple
                            /> : null
                        }
                    </div>
                </div>
                <div className="block-content">
                    <div className={`mb-5 d-flex align-items-center ${this.props.loading ? 'data-loading' : ''}`}>
                        {type != 'scan_phone' && type != 'scan_member_group'
                        ? <button type="button" className={`btn btn-primary btn-sm mt-0 p-2 d-flex align-items-center`} onClick={() => this.props.showFilter() }>
                            <FilterFilled className="mr-5" /> Filter
                        </button> : null}
                        <button type="button" className={`btn btn-success btn-sm mt-0 p-2 d-flex align-items-center ml-5`} onClick={() => this.props.visibleExport(listData) }>
                            <DownloadOutlined className="mr-5" /> Tải xuống
                        </button>
                        {
                            listDataFalse.length > 0 ? 
                            <button type="button" className={`btn btn-danger btn-sm mt-0 p-2 d-flex align-items-center ml-5`} onClick={() => this.props.onExport({
                                data: listDataFalse,
                                fileExtension: '.txt',
                                format_export: ['id']
                            })}>
                                <DownloadOutlined className="mr-5" /> Danh sách lỗi ({listDataFalse.length})
                            </button> : null
                        }
                    </div>
                    <div className="table-responsive">
                        {table}
                    </div>
                </div>
            </div>

        );
    }
}
