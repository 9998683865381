import React from "react";

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, Radio, InputNumber, Select } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled } from '@ant-design/icons';
import { fetchData } from '../lib/apis';

export default class FormOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            if (this.form){
                this.setState({
                    loading: false,
                    error: null
                }, () => {
                    this.form.resetFields();
                })
            }
        }
    }
    addOrder = async (values) => {
        try{
            this.setState({loading : true, error: null});
            let res = await fetchData({
                url: `api/v2/scan/create`,
                method: 'post',
                data: JSON.stringify({
                    id_s: window.socket.id,
                    query: {
                        timestamp: Date.now(),
                        ...values
                    },
                    type_scan: 2
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.setState({loading : false }, () => {
                this.props.getData();
                this.props.hide();
                this.props.notification({
                    content: 'Thao tác thành công',
                    title: this.props.title,
                    type: 'success'
                })
            });
        } catch(err){
            this.setState({loading : false, error: err.message || 'Đã có lỗi xảy ra' })
            // this.props.notification({
            //     content: err.message || 'Đã có lỗi xảy ra',
            //     title: this.props.title,
            //     type: 'error',
            //     duration: 8
            // })
        }
    }
    render() {
        let { type } = this.props;
        let form = null;
        if(type == 'add_scan_cmt'){
            form = <Form
                ref={(evt) => this.form = evt}
                onFinish={this.addOrder}
                initialValues={{
                    type: "scan_comment_hidden"
                }}
            >
                {
                    this.state.error ? 
                    <div className="alert alert-danger">{this.state.error}</div> : null
                }
                <Form.Item name="type" noStyle>
                    <Input type="hidden"/>
                </Form.Item>
                {/* <Form.Item label="Token" name="access_token"
                    rules={[{ required: true, message: 'Access token bắt buộc' }]}
                >
                    <Input />
                </Form.Item> */}
                <div className="alert alert-primary">
                    Chú ý: Quản lý và kiểm tra token quét comment ở <a href="/me/token" target="_blank" style={{textDecoration: "underline"}}>đây</a>
                </div>
                <Form.Item label="ID bài viết"
                    name="post_id"
                    rules={[{ required: true, message: 'Id bài viết bắt buộc' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Ghi chú"
                    name="note"
                >
                    <Input.TextArea style={{minHeight: "100px"}}/>
                </Form.Item>
                <div className="text-right">
                    <Button htmlType="submit" type="primary" loading={this.state.loading}>Thêm mới</Button>
                </div>
            </Form>
        }

        return (
            <Modal
                title={this.props.title || 'form'}
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
            >
                {form}
            </Modal>
        );
    }
}
