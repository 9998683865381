import React from "react";
import NumberFormat from 'react-number-format';
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Button, Modal, Pagination, Spin, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import qs from 'qs';
import { UploadFile } from '../../component';

export default class ManageUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            loading: false
        };
        this.limit = 20;
        this.page = 1;
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async () => {
        try {
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            let response = await fetchData({
                url: `api/v1/admin/proxy?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                type: "error",
                content: err.message || "Lỗi lấy dữ liệu",
                title: "Lấy dữ liệu"
            })
        }
    }
    checkProxy = async (id) => {
        try {
            this.setState({ loading: true });
            let response = await fetchData({
                url: `api/v1/admin/proxy/test?id=${id}`,
                method: 'get'
            });
            this.props.notification({
                type: "error",
                content: "Proxy live",
                title: "Thông báo"
            })
        } catch (err) {
            this.props.notification({
                type: "error",
                content: err.message || "Lỗi lấy dữ liệu",
                title: "Thông báo"
            })
        } finally {
            this.setState({ loading: false });
        }
    }

    create = async (values) => {
        try {
            this.setState({ loadingForm: true });
            if (!values.upload) throw({ message: 'Bạn chưa chọn file!'});

            let formData = new FormData();
            formData.append('file', values.upload.file);
            formData.append('format', values.format);

            let resutl = await fetchData({
                url: 'api/v1/admin/proxy/create',
                method: 'post',
                data: formData
            });
            this.getListData();
            
            this.setState({ loadingForm: false, visibleUpload: false })
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập proxy',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập proxy',
                type: 'error'
            })
        }
    }

    delete = async (values) => {
        var { list_ids } = values;
        if (list_ids.length == 0) {
            this.props.notification({
                type: 'warning',
                title: 'Lỗi xóa',
                content: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${list_ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v1/admin/proxy/delete',
                            method: 'post',
                            data: JSON.stringify({
                                ids: list_ids
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });
                        notification({
                            type: 'success',
                            title: 'Xóa thành công',
                            content: `Bạn đã xóa thành công ${result.data.deletedCount} đối tượng!`
                        })
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            title: 'Lỗi xóa',
                            content: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }

    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <UploadFile {...this.props} visible={this.state.visibleUpload} hide={() => this.setState({ visibleUpload: false })} 
                    format={["host", "port", "user", "password"]}
                    onFinish={this.create}
                    loading={this.state.loadingForm}
                    select_format={false}
                />
                <div className="block block-rounded">
                    <div className="block-header">
                        <h3 className="block-title">Quản lý proxy</h3>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Xóa">
                                    <button type="button" className={`btn btn-danger btn-sm d-flex align-items-center ml-5`} onClick={() => {
                                        let list_ids = [];
                                        document.querySelectorAll('table input[name="check_item"]:checked').forEach((item) => list_ids.push(item.value));
                                        this.delete({
                                            list_ids: list_ids
                                        })
                                    }}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Thêm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.setState({ visibleUpload: true })}>
                                        Thêm mới
                                    </button>
                                </Tooltip>
                            </div>
                            <div className={`${this.state.loading ? 'data-loading' : ''}`}>
                                <Pagination
                                    total={this.state.total}
                                    defaultCurrent={1}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getListData();
                                    }}
                                    onShowSizeChange={(current, size) => {
                                        this.page = current;
                                        this.limit = size;
                                        this.getListData();
                                    }}
                                    pageSizeOptions={[20, 50, 100, 200, 500, 1000, 2000]}
                                />
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Spin spinning={this.state.loading}>
                                <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                    <thead>
                                        <tr>
                                            <th className="text-left">
                                                <label className="css-control css-control-primary css-checkbox p-0">
                                                    <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}
                                                    />
                                                    <span className="css-control-indicator"></span>
                                                </label>
                                            </th>
                                            <th>Host:Port</th>
                                            <th>User:Password</th>
                                            <th>Trạng thái</th>
                                            <th>Last time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                return <tr key={item._id}>
                                                    <td className="text-left">
                                                        <label className="css-control css-control-primary css-checkbox p-0">
                                                            <input name="check_item" type="checkbox" className="css-control-input checkbox" value={item._id}
                                                                onChange={this.props.handleChecked}
                                                            />
                                                            <span className="css-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>{item.host}/{item.port}</td>
                                                    <td>{item.user ? `${item.user}/${item.password}` : ''}</td>
                                                    <td>{item.status == 1 ? "live" : "die"}</td>
                                                    <td>{item.last_time ? moment(item.last_time).format('HH:mm DD-MM-YYYY') : ''}</td>
                                                    <td>
                                                        <Button
                                                            size="small"
                                                            onClick={() => this.checkProxy(item._id)}
                                                        >Check live</Button>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
