import React from "react";

export default class MeGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: []
    };
  }
  render() {
    return (
        <div>
            <div className="pb-20">
                <h3 className="block-title block-title-m ">Quét group của UID</h3>
            </div>
            <div className="form-group">
                {
                  this.props.userScan ?
                  <div className="alert mt-5 pt-2 pb-2" style={{background: "#eff2f5"}}>
                  {this.props.userScan.id} - { this.props.userScan.name }
                  </div> : null
                }
                {
                  this.props.errorToken ? 
                  <div className="alert mt-5 pt-2 pb-2 alert-danger">
                  {this.props.errorToken}
                  </div> : null
                }
                <label htmlFor="inputToken">Nhập token</label>
                <input name="access_token" type="text" id="inputToken" className="form-control" placeholder="Nhập token" defaultValue={this.props.userScan && this.props.userScan.access_token} />
            </div>
            <div className="form-group">
                <label >Nhập cookie</label>
                <textarea name="cookie" type="text" className="form-control" placeholder="Nhập cookie" defaultValue={this.props.userScan && this.props.userScan.cookie} style={{minHeight: "100px"}}></textarea>
            </div>
            <div className="form-group">
            <label>Danh sách: {this.state.listData.length}</label>
                <textarea name="uids" className="form-control" placeholder="" style={{minHeight: "300px"}} onChange={(e) => {
                  this.setState({
                    listData: e.target.value ? e.target.value.split('\n') : []
                  })
                }}/>
                <p style={{marginTop: "10px"}}>Chú ý: Mỗi UID 1 dòng (tối đa 50k).</p>
            </div>
        </div>
    );
  }
}
