import { dimens, AccountMetaData } from  '../config/app';

export function fetchData(data = {}){
    let url = `${process.env.REACT_APP_API_URL}/${data['url']}`;
    let options = {
        method: data.method ? data.method : 'GET',
        headers: {
            'Authorization' : AccountMetaData.getToken()
        }
    };
    if(options.method.toLocaleLowerCase() != 'get'){
        options['body'] = data.data;
    }
    if(data['headers']) {
        options['headers'] = {
            ...options['headers'],
            ...data['headers']
        }
    }

    return new Promise((resolve, reject) => {
        fetch(url, options)
        .then(response =>  {
            return response.json();
        })
        .then((response) => {
            if(response.code == 401) {
                AccountMetaData.logOut();
                return resolve(response);
            }
            if(response.code && response.code != 200 || response.status_code && response.status_code != 200) return reject(response);
            return resolve(response);
        }).catch((err) => {
            return reject({
                code: 500,
                err: err
            });
        })
    })
}

export function requestApiFb(data = {}){
    let url = 'https://graph.facebook.com'+data['url'];
    let options = {
        method: data.method ? data.method : 'GET'
    };
    if(options.method.toLocaleLowerCase() != 'get'){
        options['body'] = data.data;
    }
    options['headers'] = {};
    if(!data.type){
        options['headers']['Authorization'] = dimens['auth.token.facebook']
    }
    if(data.access_token){
        options['headers']['Authorization'] = 'Bearer '+data.access_token;
    }
    console.log(options);
    return new Promise((resolve, reject) => {
        fetch(url, options)
        .then(response =>  {
            return response.json();
        })
        .then((response) => {
            if(response.error) return reject(response.error);
            return resolve(response);
        }).catch((err) => {
            return reject({
                code: 500,
                err: err
            });
        })
    })
}