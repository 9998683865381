import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Select, Form, Input} from 'antd';
import qs from 'qs';
import moment from 'moment';
import { FormOrder, LogScan} from '../component';
import { fetchData } from '../lib/apis';
const { Option } = Select;

export default class ScanComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0
        };
        this.limit = 16;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getData();
    }
    getData = async () => {
        try {
            this.setState({ loading: true });
            let query = {
                limit: this.limit,
                page: this.page,
                type: 'scan_comment_hidden'
            }
            if(this.formFilter){
                query = {
                    ...query,
                    ...this.formFilter.getFieldsValue()
                }
            }
            let response = await fetchData({
                url: `api/v2/scan/list?${qs.stringify(query)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false })
        }
    }
    delete = async (option = {}) => {
        let _this = this;
        let { item, type } = option;
        let ids = [];
        if (type == 'delete_manny') {
            let items_checked = document.querySelectorAll('[name="check_item"]:checked');
            if (items_checked.length == 0) {
                _this.props.notification({
                    content: 'Bạn phải chọn ít nhất 1 đơn!',
                    title: 'Xóa quét comment ẩn',
                    type: 'error',
                    key: 'delete'
                })
                return
            }
            items_checked.forEach((item) => {
                ids.push(item.value);
            })
        } else {
            ids = [item._id];
        }
        Modal.confirm({
            title: 'Xóa quét comment ẩn',
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v2/scan`,
                            method: 'delete',
                            data: JSON.stringify({
                                ids: ids
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });
                        _this.getData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa đơn quét comment ẩn',
                            type: 'success',
                            key: 'delete'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Thao tác thất bại',
                            title: 'Xóa đơn quét comment ẩn',
                            type: 'error',
                            key: 'delete'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    update = async ({ item, data, title }) => {
        let _this = this;
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v2/scan/${item._id}`,
                            method: 'put',
                            data: JSON.stringify(data),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });
                        _this.getData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: title,
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Thao tác thất bại',
                            title: title,
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    render() {
        let { listData } = this.state;

        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <FormOrder {...this.props} title="Quét comment ẩn" visible={this.state.visibleForm} hide={() => this.setState({ visibleForm: false })} type={this.state.visibleFormType}
                    getData={this.getData}
                />
                <LogScan {...this.props} visible={this.state.LogScan} orderId={this.state.LogScan} hide={() => this.setState({ LogScan: null})}/>

                <div className="block block-rounded">
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Quét comment ẩn</h3>
                        </div>
                        <Button type="primary" className="d-flex align-items-center" onClick={() => this.setState({ visibleForm: true, visibleFormType: 'add_scan_cmt' })}>Thêm mới</Button>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Xóa">
                                    <button type="button" className={`btn btn-danger btn-sm d-flex align-items-center ml-5`} onClick={() => this.delete({ type: 'delete_manny' })}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                                <Link to="/me/token" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`}>
                                    Quản lý token
                                </Link>
                            </div>
                            <div className={`${this.props.loading ? 'data-loading' : ''}`}>
                                <Pagination
                                    total={this.state.total}
                                    defaultCurrent={1}
                                    showSizeChanger={false}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getData();
                                    }}
                                    simple
                                />
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: ""
                            }}
                            onChange={(e) => {
                                if(e.target.nodeName == 'INPUT'){
                                    if(this.timeout) clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        this.getData();
                                    }, 300)
                                } else {
                                    this.getData();
                                }
                            }}
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row tool-filter">
                                <div className="col-md-2 col-lg-2 pl-15 pr-5">
                                    <Form.Item name="post_id">
                                        <Input placeholder="Post id"/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => {
                                            this.getData();
                                        }}>
                                            <Option value="">Trạng thái</Option>
                                            <Option value={1}>Đang quét</Option>
                                            <Option value={2}>Thành công</Option>
                                            <Option value={3}>Tạm dừng</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Xóa lọc">
                                        <Button className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getData();
                                        }}></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Spin tip="loading.." spinning={this.state.loading}>
                                <table className="table table-striped table-vcenter table-text-center table-white-space">
                                    <thead>
                                        <tr>
                                            <th>
                                                <label className="css-control css-control-primary css-checkbox p-0">
                                                    <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}
                                                    />
                                                    <span className="css-control-indicator"></span>
                                                </label>
                                            </th>
                                            <th>#</th>
                                            <th>Post Id</th>
                                            <th>Số lượng quét được</th>
                                            <th>Trạng thái</th>
                                            <th>Ghi chú</th>
                                            <th>Ngày tạo</th>
                                            <th>Hoạt động</th>
                                            <th>Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listData.length > 0 ? listData.map((item, i) => {
                                                return <tr key={item._id}>
                                                    <td>
                                                        <label className="css-control css-control-primary css-checkbox p-0">
                                                            <input name="check_item" type="checkbox" className="css-control-input checkbox" value={item._id}
                                                                onChange={this.props.handleChecked}
                                                            />
                                                            <span className="css-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                    <td><a href={`https://fb.com/${item.query.post_id}`} target="_blank">{item.query.post_id}</a></td>
                                                    <td>{item.num_success}</td>
                                                    <td>
                                                        {item.status == 0 || item.status == 1 ? <p className="alert alert-warning p-0 m-0">Đang quét</p> : item.status == 2 ? <p className="alert alert-sucess p-0 m-0">Thành công</p> : item.status == 3 ? <p className="alert alert-primary p-0 m-0">Tạm dừng</p> : <p className="alert alert-danger p-0 m-0">Thất bại</p>}
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <textarea disabled style={{ width: "160px", height: "60px" }} className="form-control">{item.query.note || ''}</textarea>
                                                        </div>
                                                    </td>
                                                    <td>{moment(item.time_created).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td>{item.last_time ? moment(item.last_time).format('HH:mm DD-MM-YYYY') : ''}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Tooltip title="Dữ liệu đã quét">
                                                                <Link to={`/app/scan-comment-hidden/${item._id}`} className="btn btn-sm btn-primary mr-5">
                                                                    <i className="fa fa-eye"></i>
                                                                </Link>
                                                            </Tooltip>
                                                            <Tooltip title="Log">
                                                                <a href="#" className="btn btn-sm btn-info mr-5" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ LogScan: item._id })
                                                                }}>
                                                                    <i class="far fa-file-medical-alt"></i>
                                                                </a>
                                                            </Tooltip>
                                                            {/* <Tooltip title="Log">
                                                                <a href="#" className="btn btn-sm btn-danger mr-5" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.delete({ item: item })
                                                                }}>
                                                                    <i className="fa fa-history"></i>
                                                                </a>
                                                            </Tooltip> */}
                                                            {
                                                                item.status == 1 || item.status == 0 ?
                                                                    <Tooltip title="Tạm dừng">
                                                                        <a href="#" className="btn btn-sm btn-warning mr-5" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.update({ item, title: "Tạm dừng quét", data: { status: 3 } })
                                                                        }}>
                                                                            <i className="fa fa-pause"></i>
                                                                        </a>
                                                                    </Tooltip> : item.status == 3 ? <a href="#" className="btn btn-sm btn-warning mr-5" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.update({ item, title: "Tiếp tục quét", data: { status: 1 } })
                                                                    }}>
                                                                        <i className="fa fa-play"></i>
                                                                    </a> : null
                                                            }
                                                            <Tooltip title="Xóa nội dung">
                                                                <a href="#" className="btn btn-sm btn-danger" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.delete({ item: item })
                                                                }}>
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                    <td colSpan={11}>Chưa có dữ liệu</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
