import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';

import {
    TopInteractive, ExportData
} from '../component';
import { Button, notification, Spin, Pagination, Upload, Modal } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import io from 'socket.io-client';
import { fetchData, requestApiFb } from '../lib/apis';
import qs from 'qs';

export default class HomeView2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            loading: false,
            listDataFalse: [],
            type_app: "",
            listToken: []
        };
        this.idScan = null;
    }
    componentWillMount = async () => {
        try {
            window.socket.on('connect', data => {
                if (this.idScan) {
                    window.socket.emit('update-scan', {
                        _id: this.idScan,
                        update: {
                            "query.socket_id": window.socket.id
                        }
                    })
                }
            })
            window.socket.on('start', data => {
                this.idScan = data._id;
            })

            window.socket.on('complete', response => {
                try {
                    let option = response.option;
                    let result = response.result;
                    if (response.orderId != this.idScan) {
                        return
                    };

                    let listData = this.state.listData;
                    let listDataFalse = this.state.listDataFalse;
                    let limit = option.limit ? parseInt(option.limit) : false;

                    result.data.forEach((item) => {
                        listData.push(item);
                    })
                    if (limit) {
                        listData = listData.splice(0, limit);
                    }
                    if (result.convert_false) {
                        result.convert_false.data.forEach((item) => {
                            listDataFalse.push(item);
                        })
                    }
                    this.setState({ loading: !response.success, listData: listData, listDataFalse: listDataFalse }, () => {
                        if (response.success) {
                            this.idScan = null;
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            })
            window.socket.on('result-error', result => {
                let error = result.error;
                if (this.state.type_app == result.option.type) {
                    this.setState({ loading: false }, () => {
                        notification['error']({
                            message: 'Smart target quét dữ liệu',
                            description: typeof error == 'object' ? error.message || error.error && error.error.message || 'Lỗi quét, vui lòng thử lại.' : 'Lỗi quét, vui lòng thử lại.'
                        })
                    });
                }
            })
        } catch (err) {

        }

    }
    componentDidMount = async () => {
        // this.stopScan();
        this.getListToken();
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.location && nextProps.location.pathname != this.props.location.pathname) {
            this.stopScan('reset');
        }
    }
    scanData = async (e) => {
        try {
            let values = await e.validateFields();
            await this.checkUsePackage();

            this.setState({ loading: true, listData: [], listDataFalse: []});

            if (document.querySelector('#result')) document.querySelector('#result').value = "";
            if (document.querySelector('#result-false')) document.querySelector('#result-false').value = "";

            let res = await fetchData({
                url: `api/v2/scan/create`,
                method: 'post',
                data: JSON.stringify({
                    id_s: window.socket.id,
                    query: {
                        timestamp: Date.now(),
                        ...values
                    }
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.idScan = res.data._id;
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
            notification['error']({
                message: 'Smart target quét dữ liệu',
                description: typeof err == 'string' ? err : err.message || 'Lỗi quét, vui lòng thử lại.',
                duration: 6,
                key: 'error_scan'
            })
        }
    }
    stopScan = (type) => {
        if (this.idScan) {
            window.socket.emit('update-scan', {
                _id: this.idScan,
                update: {
                    status: 2
                }
            })
        }
        this.idScan = null;
        if (type == 'reset') {
            this.setState({
                loading: false,
                listData: [],
                listDataFalse: [],
                queryFilter: null
            })
        } else {
            this.setState({ loading: false });
        }

    }

    getListToken = async (options = {}) => {
        try {
            this.setState({ loadingGetToken : true });
            let { name } = options;
            let params = {
                limit: 10,
                status: 1
            }
            if (name) params['name'] = name;

            let response = await fetchData({
                url: `api/v2/scan/token/list?${qs.stringify(params)}`,
                method: 'get'
            });

            this.setState({
                listToken: response.data,
                loadingGetToken: false
            })

        } catch (err) {
            this.setState({ loadingGetToken : false });
            this.props.notification({
                type: "error",
                content: err.message || "Đã có lỗi xảy ra",
                title: "Lấy token quét"
            })
        }
    }

    checkUsePackage = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let user = await this.props.getDetailUser();
                let user_package = user.package;
                if (user_package.end_time < Date.now()) {
                    return reject({ message: 'Gói cước bạn đã hết hạn, vui lòng nâng cấp để tiếp tục sử dụng!' })
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }

    render() {
        let props_m = {
            ...this.props,
            getListToken: this.getListToken,
            listData: this.state.listData,
            listDataFalse: this.state.listDataFalse,
            loadingGetToken: this.state.loadingGetToken,
            listToken: this.state.listToken,
            showGuide: () => this.setState({ visibleGuides: true }),
            loadingScan: this.state.loading,
            stopScan: this.stopScan,
            startScan: this.scanData
        }
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>

                <ExportData visible={this.state.visibleExport} hide={() => this.setState({ visibleExport: false })} type={this.state.type_app}
                    onExport={this.props.onExport} data={this.state.dataExport || []} loading={this.state.loadingExport} formatExport={this.formatExport}
                />

                <Modal title="Hướng dẫn" 
                    visible={this.state.visibleGuides}
                    footer={null}
                    onCancel={() => this.setState({ visibleGuides: false })}
                >
                    <p>
                        - Token chỉ định dùng để quét.
                    </p>
                    <p>
                        - Bạn có thể thêm token <Link to="/me/token">tại đây</Link>.
                    </p>
                    <p>
                        - Token chỉ định để trống mặc định sẽ lấy tất cả trong kho token.
                    </p>
                </Modal>

                <Switch>
                    <Route
                        exact
                        path='/app2/interactive'
                        render={props => <TopInteractive {...props_m} {...props} type_app="scan_top_interactive" />}
                    />
                </Switch>

            </div>
        )
    }
}
