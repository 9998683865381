import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { message, Spin, notification, Modal } from 'antd'
import { fetchData, requestApiFb} from './lib/apis';
import { setCookie } from './config/app';

import { MainlayoutView, MainlayoutView2, LoginView } from './views';

class AppComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }
  componentDidMount = () => {
    // if(!localStorage.getItem("read_noti_m") && checkIssetLogin()){
    //   setTimeout(() => {
    //     this.notificationMain();
    //   }, 1000)
    // }
  }
  notificationMain = () => {
    Modal.info({
      title: 'Thông báo',
      className: 'modal-noti',
      content: (
        <div className="content">
          <p>Hệ thống mới nâng cấp, người dùng dịch vụ <strong>Fanpage</strong> vui lòng cập nhập lại page để tiếp tục sử dụng.</p>
          <p style={{ marginBottom: "0px" }}>Xin cảm ơn!</p>
        </div>
      ),
      onOk() {
        localStorage.setItem("read_noti_m", 1);
      },
    });
  }

  notification = (options) => {
    let { type, title, content, key, duration } = options;
    notification[type]({
      message: title,
      description: content,
      key: key,
      duration: duration ? duration : 5
    })
  }
  message = (options) => {
    let { type, key, content, duration } = options;
    message[type]({
      content: content,
      key: key,
      duration: duration
    })
  }

  getInfoUser = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await fetchData({
          url: 'api/v2/me',
          method: 'get'
        });
        let user_fb = await requestApiFb({
          url: '/me?fields=about,name,picture{url},email',
          method: 'get'
        });
        setCookie('usr', encodeURIComponent(JSON.stringify(response.data)), 7);
        this.setState({
          user: response.data,
          service: response.service
        })
      } catch (err) {
        return reject(err);
      }
    })
  }
  getDetailUser = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await fetchData({
          url: 'api/v2/me',
          method: 'get'
        });
        this.setState({
          user: response.data,
          service: response.service
        })
        return resolve(response.data);
      } catch (err) {
        return reject(err);
      }
    })
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path='/login'
            render={props => <LoginView {...props} notification={this.notification} message={this.message} user={this.state.user} updateUser={(user) => this.setState({ user: user})}/>}
          />
          <Route
            path='/'
            render={props => <MainlayoutView2 {...props} notification={this.notification} message={this.message} user={this.state.user} 
            getInfoUser={this.getInfoUser} service={this.state.service} getDetailUser={this.getDetailUser}
            />}
          />

        </Switch>
      </Router>
    )
  }
}
export default AppComponent;
