import React, { Component } from 'react';
import { Modal, Button, Spin, Tooltip, Pagination} from 'antd';
import { fetchData } from '../lib/apis';
import moment from 'moment';
import qs from 'qs';

export default class LogScan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loading: false
        }
        this.form = null;
        this.orderId = this.props.orderId;
        this.limit = 10;
        this.page = 1;
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            this.orderId = nextProps.orderId;
            this.getListData();
        }
    }
    getListData = async () => {
        try {
            this.setState({
                loading: true
            })
            let query = {
                limit: this.limit,
                page: this.page
            }
            let res = await fetchData({
                url: `api/v2/scan/${this.orderId}/log?${qs.stringify(query)}`,
                method: 'get'
            });
            this.setState({
                loading: false,
                listData: res.data,
                total: res.total
            })
        } catch (err) {
            this.setState({
                loading: false
            })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xem log',
                type: 'error'
            })
        }
    }
    render() {
        return (
            <Modal
                title={"Log quét"}
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
                width={1000}
            >
                <div>
                    <div className="d-sm-flex align-items-center justify-content-between mb-10">
                        <div className="d-flex mb-10">
                            <button className={`btn btn-primary btn-sm d-flex`}>
                                Tất cả: {this.state.total}
                            </button>
                            <Tooltip title="Làm mới">
                                <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getListData()}>
                                    <i className="fa fa-refresh"></i>
                                </button>
                            </Tooltip>
                        </div>
                        <div>
                            <Pagination
                                total={this.state.total}
                                defaultCurrent={1}
                                showSizeChanger={false}
                                pageSize={this.limit}
                                current={this.page}
                                onChange={(current) => {
                                    this.page = current;
                                    this.getListData();
                                }}
                                simple
                            />
                        </div>
                    </div>
                    <div className="table-responsive">
                        <Spin tip="loading.." spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter table-white-space">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Log</th>
                                        <th>Thời gian</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                            return <tr key={item._id}>
                                                <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                <td>
                                                    <p className="m-0" style={{ whiteSpace: "pre-line" }}>{item.log}</p>
                                                </td>
                                                <td>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                            </tr>
                                        }) : <tr>
                                                <td className="text-center" colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </Modal>
        )
    }
}
