import React from "react";

export default class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <div className="content" style={{ height: "100%", overflow: "auto" }}>
            <div className="block d-flex justify-content-center">
              <iframe style={{ height: "2000px", width: "100%", objectFit: "cover", border: "none", maxWidth: "820px"}} src="https://docs.google.com/document/d/e/2PACX-1vRa9IovpOMgfJmyg3ZMioqne_gvI9iL9Z6ipOe9AEQg0BcVdbOOxmtFj0f3V5GMFg/pub?embedded=true"
              ></iframe>
            </div>
        </div>
    );
  }
}
