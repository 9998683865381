import React from "react";
import { Upload, Button, message, Select, Form, notification } from 'antd';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

export default class FilterFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            sectionFilter: "",
            loading: false,
            dataFilterFile: []
        };
    }
    componentDidMount = async () => {

    }
    filterData = async () => {
        try {
            if (this.state.fileList.length < 2) throw ('Bạn phải chọn ít nhất 2 file');
            this.setState({ loading: true });
            let promise = [];
            this.state.fileList.forEach((item) => promise.push(this.readFile(item)));
            let results = await Promise.all(promise);
            let data = [];

            results.forEach((file, i) => {
                results[i]['uids_1'] = [];
                results[i]['uids_2'] = [];
                let find_section_other = results.filter((item) => item.file_id != file.file_id);
                let uids_other = [];
                find_section_other.forEach((item, i) => {
                    uids_other = uids_other.concat(item.data);
                })
                file.data.forEach((item) => {
                    if(item){
                        if (uids_other.indexOf(item) == -1) {
                            results[i]['uids_1'].push(item);
                        } else {
                            results[i]['uids_2'].push(item)
                        }
                    }
                })
            })

            if (!this.state.sectionFilter) {
                data = results[0]['uids_2'];
            } else if (this.state.sectionFilter == 'key_1') {
                results.forEach((item) => {
                    data = data.concat(item.uids_1);
                })
            } else {
                let find_section = results.find((item) => item.file_id == this.state.sectionFilter);
                if (find_section) data = find_section.uids_1;
            }
            notification['success']({
                message: 'Lọc dữ liệu',
                description: 'Thao tác thành công'
            })
            console.log(data);

            document.querySelector('#result-filter').value = data.join('\n');
            
            this.setState({ loading: false, dataFilterFile: data });
        } catch (err) {
            this.setState({ loading: false });
            notification['error']({
                message: 'Lọc dữ liệu',
                description: typeof err == 'string' ? err : 'Lỗi xử lý dữ liệu'
            })
        }
    }
    readFile = (file) => {
        return new Promise((resolve, reject) => {
            var result = new FileReader();
            result.onload = function () {
                resolve({
                    file_id: file.uid,
                    name: file.name,
                    data: [...new Set(result.result.split('\n'))]
                });
            }
            result.readAsText(file);
        })
    }
    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="row flex-md-row" style={{ height: "100%" }}>
                    <div className="col-md-4">
                        <div className="block block-rounded d-flex">
                            <div className="block-content d-flex">
                                <div style={{ width: "100%" }}>
                                    <div className="pb-20">
                                        <h3 className="block-title block-title-m ">Lọc dữ liệu</h3>
                                    </div>
                                    <div className="form-group">
                                        <Dragger
                                            onRemove={file => {
                                                this.setState(state => {
                                                    const index = state.fileList.indexOf(file);
                                                    const newFileList = state.fileList.slice();
                                                    newFileList.splice(index, 1);
                                                    return {
                                                        fileList: newFileList,
                                                    };
                                                });
                                            }}
                                            beforeUpload={file => {
                                                if(file.type == 'text/plain'){
                                                    this.setState(state => ({
                                                        fileList: [...state.fileList, file],
                                                    }));
                                                }
                                                return false;
                                            }}
                                            fileList={this.state.fileList}
                                            className="p-5 list-file-filter"
                                            style={{ width: "100%" }}
                                            multiple={ true }
                                            onChange={(file) => {
                                                this.setState({
                                                    sectionFilter: ""
                                                })
                                            }}
                                            listType="text"
                                        >
                                            <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">
                                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                            band files
                                            </p>
                                        </Dragger>
                                    </div>
                                    <div className="form-group">
                                        <label>Tổng file đang chọn: {this.state.fileList.length}</label>
                                    </div>
                                    <div className="form-group">
                                        <label>Chọn vùng dữ liệu</label>
                                        <div>
                                            <Select style={{width: "100%"}} value={this.state.sectionFilter} onChange={(e) => this.setState({sectionFilter: e })}>
                                                <Select.Option value="">Dữ liệu chung</Select.Option>
                                                <Select.Option value="key_1">Dữ liệu không trùng lặp</Select.Option>
                                                {this.state.fileList.map((item, i) => {
                                                    return <Select.Option key={item.uid} value={item.uid}>{item.name}</Select.Option>
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <Button type={"primary"} style={{ width: "100%", height: "40px", fontSize: "16px", borderRadius: "5px" }}
                                            onClick={this.filterData} loading={this.state.loading}
                                        >Xử lý dữ liệu</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="block block-rounded mb-0 d-flex flex-md-column">
                            <div className="block-header pb-0 justify-content-between align-items-center">
                                <h3 className="block-title">Kết quả : <span className="text-success">{this.state.dataFilterFile.length}</span></h3>
                                <button type="button" className={`btn btn-primary btn-sm mt-0 p-2 d-flex align-items-center ml-5`} onClick={() => this.props.onExport({
                                    data: this.state.dataFilterFile,
                                    fileExtension: '.txt'
                                })}>
                                    <DownloadOutlined className="mr-5" /> Tải xuống ({this.state.dataFilterFile.length})
                                </button> 
                            </div>
                            <div className="block-content d-flex" style={{ height: "100%", flex: "auto" }}>
                                <div className="form-group d-flex" style={{ flex: "auto" }}>
                                    <textarea id="result-filter" className="form-control flex-auto" style={{ flex: "auto" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
