import React, { Component } from 'react';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, Radio, InputNumber, Select} from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled } from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { fetchData } from '../lib/apis';
import { formatPrice } from '../lib/helpers'

export default class upgradePackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_price_upgrade: 0,
            loading: false
        }
        this.form = null;
    }
    componentWillUpdate = (nextProps, nextState) => {
        try{
            if (nextProps.visible && nextProps.visible != this.props.visible) {
                if(this.form){
                    this.setState({
                        total_price_upgrade: 0,
                        package_upgrade: null
                    }, () => {
                        this.form.resetFields();
                    })
                }
            }
        } catch(err){

        }
    }
    changeUpgrade = () => {
        let upgrade_num_uid = parseInt(this.form.getFieldValue("upgrade_num_uid")) || 0;
        let upgrade_num_phone = parseInt(this.form.getFieldValue("upgrade_num_phone")) || 0;
        this.setState({
            total_price_upgrade: (upgrade_num_uid * this.props.service.price_one_uid) + (upgrade_num_phone * this.props.service.price_one_phone)
        })
    }
    upgrade = async (values) => {
        try{
            this.setState({loading : true })
            let res = null;
            if(this.props.type == 'scan_comment_hidden'){
                res = await fetchData({
                    url: `api/v2/service/transaction`,
                    method: 'post',
                    data: JSON.stringify({
                        ...values
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
            } else {
                res = await fetchData({
                    url: `api/v2/service/transaction`,
                    method: 'post',
                    data: JSON.stringify({
                        ...values,
                        type_package: 2
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
            }
            this.setState({loading : false }, () => {
                this.props.hideUpgrade();
                this.props.showDetailPayment(true, res.data, null, true);
            })
        } catch(err){
            this.setState({loading : false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Đăng ký gói cước',
                type: 'error'
            })
        }
    }
    render() {
        let body = null;
        let package_comment_hidden = this.props.service.packages.find((item) => item.package_comment_hidden);

        if(this.props.type == 'scan_comment_hidden' && package_comment_hidden && package_comment_hidden.packages.length > 0){
            console.log('ss', this.state.package_upgrade ? this.state.package_upgrade['time'] : package_comment_hidden['packages'][0]['time']);
            body = <div>
                <div className="export-container" style={{ minHeight: "unset" }}>
                    {
                        <div className="text-center">
                            <Form
                                initialValues={{
                                    max_thread: package_comment_hidden.packages[0]['max_thread'],
                                    package_id: package_comment_hidden.id
                                }}
                                ref={(evt) => this.form = evt}
                                onFinish={this.upgrade}
                            >
                                <Form.Item name="package_id" noStyle>
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="max_thread" label={`Chọn gói cước`} className="text-left">
                                    <Select onChange={(e) => {
                                        let find = package_comment_hidden.packages.find((item) => item.max_thread == e);
                                        if(find){
                                            this.setState({
                                                package_upgrade: find
                                            })
                                        }
                                    }}>
                                        {
                                            package_comment_hidden.packages.map((item, i) => {
                                                return <Select.Option key={i} value={item.max_thread}>
                                                    {item.name.toUpperCase()}
                                                </Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <div className="text-left export-option pt-10">
                                    <p className="mb-10"><b>Tổng tiền: <span className="text-danger"><NumberFormat value={this.state.package_upgrade ? this.state.package_upgrade['price'] : package_comment_hidden.packages[0]['price']} displayType={'text'} thousandSeparator={true} /> đ</span></b></p>
                                    <p className="mb-10"><b>Thời hạn gói cước: { this.state.package_upgrade ? this.state.package_upgrade['time_text'] : package_comment_hidden['packages'][0]['time_text'] }</b></p>
                                    <p className="mb-10"><b>Ngày hết hạn: {moment(Date.now() + ( 60 * 1000 * 60 * 24 * (this.state.package_upgrade ? this.state.package_upgrade['time'] : package_comment_hidden['packages'][0]['time']))).format('DD-MM-YYYY')}</b></p>
                                </div>
                                <Button htmlType="submit" className="btn btn-primary d-flex m-auto mt-20 mb-5" loading={this.state.loading}
                                >
                                    Đăng ký
                                </Button>
                            </Form>
                        </div>
                    }
                </div>
            </div>
        } else {
            body = <div>
                <div className="export-option text-left pt-15">
                    <h5 className="title m-0">Bạn đang sử dụng gói {this.props.user.package.name}</h5>
                    <div className="p-10 pl-20">
                        <p className="m-0 mb-5">
                            {
                                this.props.user.package.max_uid == -1 ? '- Không giới hạn số lượt quét UID' :
                                `- Bạn còn ${formatPrice(this.props.user.package.num_uid)} lượt quét UID`
                            }
                        </p>
                        <p className="m-0 mb-5">
                            {
                                this.props.user.package.max_phone == -1 ? '- Không giới hạn số lượt quét SDT' : 
                                `- Bạn còn ${formatPrice(this.props.user.package.num_phone)} lượt quét SDT`
                            }
                        </p>
                        <p className="m-0">
                            - Ngày hết hạn: { moment(this.props.user.package.end_time).format('DD-MM-YYYY') }
                        </p>
                    </div>
                </div>
                <div className="export-container" style={{ minHeight: "unset" }}>
                    {
                        this.props.user.package.is_default == 1 ?
                            <div className="text-center">
                                <Button className="btn btn-rounded btn-noborder btn-warning" onClick={() => {
                                    this.props.hideUpgrade();
                                    this.props.history.push('/package');
                                }} >
                                    Nâng cấp gói cước
                            </Button>
                            </div> :
                            <div className="text-center">
                                <Form
                                    initialValues={{
                                        upgrade_num_uid: 0,
                                        upgrade_num_phone: 0
                                    }}
                                    onChange={this.changeUpgrade}
                                    ref={(evt) => this.form = evt}
                                    onFinish={this.upgrade}
                                >
                                    <Form.Item name="upgrade_num_uid" label={`Lượt quét UID (${this.props.service.price_one_uid}đ / 1 UID)`} className="mb-10"
                                        
                                    >
                                        <InputNumber style={{width: "100%"}} />
                                    </Form.Item>
                                    {/* <Form.Item name="upgrade_num_phone" label={`Lượt quét SDT (${this.props.service.price_one_phone}đ / 1 SDT)`}>
                                        <InputNumber style={{width: "100%"}}/>
                                    </Form.Item> */}
                                    <div className="text-left">
                                        <b>Tổng tiền: <NumberFormat value={this.state.total_price_upgrade} displayType={'text'} thousandSeparator={true} /> đ</b>
                                    </div>
                                    <Button htmlType="submit" className="btn btn-rounded btn-noborder btn-primary d-flex m-auto" loading={this.state.loading}

                                    >
                                        Mua thêm
                                    </Button>
                                </Form>
                            </div>
                    }
                </div>
            </div>
        }
        return (
            <Modal
                title="Thông tin gói cước"
                visible={this.props.visible}
                onCancel={this.props.hideUpgrade}
                footer={null}
            >
                {body}
            </Modal>
        )
    }
}
