import React from "react";
import { Button, notification } from 'antd';

export default class ScanPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        uids: []
    };
  }
  componentDidMount = async () => {
    
  }
  render() {
    return (
        <div>
            <div className="pb-20">
                <h3 className="block-title block-title-m ">Quét số điện thoại của UID</h3>
            </div>
            <div className="form-group">
                <label htmlFor="inputUid">Danh sách uid: ({this.state.uids.length})</label>
                <textarea name="uids" id="inputUid" className="form-control" placeholder="" style={{minHeight: "300px"}} value={this.state.uids.join('\n')} onChange={(e) => {
                    this.setState({
                        uids: e.target.value ? e.target.value.split('\n') : []
                    })
                }}/>
                <p style={{marginTop: "10px"}}>Chú ý: Mỗi uid 1 dòng (tối đa 50k UID).</p>
            </div>
        </div>
    );
  }
}
