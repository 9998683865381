import React from "react";
import NumberFormat from 'react-number-format';
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default class ManageUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            detailBanking: null,
            valuesFilter: {},
            services: []
        };
        this.limit = 15;
        this.page = 1;
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            let query = `limit=${this.limit}&page=${this.page}&sort=1`;
            for (let [key, value] of Object.entries(this.state.valuesFilter)) {
                query += `&${key}=${value}`;
            }
            let response = await fetchData({
                url: `api/v2/ow/user/list?${query}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                services: response.services
            })
        } catch (err) {

        }
    }
    
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 200)
    }
    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded">
                    <div className="block-header">
                        <h3 className="block-title">Quản lý người dùng: {this.state.total}</h3>
                        {
                            this.state.total > 0 ?
                                <Pagination
                                    total={this.state.total}
                                    defaultCurrent={this.page}
                                    showSizeChanger={false}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getListData();
                                    }}
                                    simple
                                /> : null
                        }
                    </div>
                    {
                        this.props.user.is_root ?
                        <div className="block-header">
                            <div className="d-flex align-items-center">
                                {/* <input placeholder="Tìm theo mã" style={{ width: "250px", marginRight: "10px" }} onChange={this.handleFilterSearch} name="code" className="form-control" /> */}
                                <select className="form-control" name="service_id" onChange={this.handleFilterSearch}>
                                    <option value="">Domain</option>
                                    {
                                        this.state.services.map((item, i) => {
                                            return <option key={i} value={item._id}>{item.domain}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div> : null
                    }
                    <div className="block-content">
                        <div className="table-responsive">
                            <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tên</th>
                                        <th>Gói đang dùng</th>
                                        <th>Email</th>
                                        <th>Địa chỉ</th>
                                        {
                                            this.props.user.is_root ?
                                            <th>domain</th> : null
                                        }
                                        <th>Hoạt đồng gần đây</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                            return <tr key={i}>
                                                <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                <td>{item.display_name}</td>
                                                <td>{item.package ? item.package.name : ''}</td>
                                                <td>{item.email}</td>
                                                <td>{item.address}</td>
                                                {
                                                    this.props.user.is_root ?
                                                    <td>{item.service ? item.service.domain : null}</td> : null
                                                }
                                                <td>{item.last_time ? moment(item.last_time).format('HH:mm DD-MM-YYYY') : ''}</td>
                                            </tr>
                                        }) : <tr>
                                                <td colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
