import React from "react";
import NumberFormat from 'react-number-format';
import { fetchData } from '../lib/apis';
import moment from 'moment';
import { Modal, Pagination} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'

export default class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0
        };
        this.limit = 10;
        this.page = 1;
    }
    componentDidMount = () => {
        this.geData();
    }
    geData = async () => {
        try {
            let response = await fetchData({
                url: `api/v2/scan/list?limit=${this.limit}&page=${this.page}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total
            })
        } catch (err) {

        }
    }
    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded mt-30" style={{ minHeight: "unset" }}>
                    <div className="block-header">
                        <h3 className="block-title">Lịch sử quét</h3>
                        {
                            this.state.total > 0 ? 
                            <Pagination
                                total={this.state.total}
                                defaultCurrent={this.page}
                                showSizeChanger={false}
                                pageSize={this.limit}
                                current={this.page}
                                onChange={(current) => {
                                    this.page = current;
                                    this.geData();
                                }}
                                simple
                            /> : null
                        }
                    </div>
                    <div className="block-content">
                        <div className="table-responsive">
                            <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Dịch vụ</th>
                                        <th>SL quét được</th>
                                        <th>Trạng thái</th>
                                        <th>Thời gian</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                            return <tr key={i}>
                                                <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                <td>{this.props.name_service[item.query.type]}</td>
                                                <td>{item.num_success}</td>
                                                <td>
                                                    {item.status == 0 || item.status == 1 ? <p className="m-0">Đang quét</p> : item.status == 2 ? <p className="text-success m-0">Thành công</p> : <p className="text-danger m-0">Thất bại</p>}
                                                </td>
                                                <td>{moment(item.time_created).format('HH:mm DD-MM-YYYY')}</td>
                                            </tr>
                                        }) : <tr>
                                                <td colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
