import React from "react";
import { Button, notification } from 'antd';

export default class SearchFanpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    
  }
  render() {
    return (
        <div>
            <div className="pb-20">
                <h3 className="block-title block-title-m ">Tìm fanpage theo từ khóa</h3>
            </div>
            <div className="form-group">
                {
                  this.props.userScan ?
                  <div className="alert mt-5 pt-2 pb-2" style={{background: "#eff2f5"}}>
                  {this.props.userScan.id} - { this.props.userScan.name }
                  </div> : null
                }
                {
                  this.props.errorToken ? 
                  <div className="alert mt-5 pt-2 pb-2 alert-danger">
                  {this.props.errorToken}
                  </div> : null
                }
                <label htmlFor="inputToken">Nhập token</label>
                <input name="access_token" type="text" id="inputToken" className="form-control" placeholder="Nhập token" defaultValue={this.props.userScan && this.props.userScan.access_token} />
            </div>
            <div className="form-group">
                <label >Nhập cookie</label>
                <textarea name="cookie" type="text" className="form-control" placeholder="Nhập cookie" defaultValue={this.props.userScan && this.props.userScan.cookie} style={{minHeight: "100px"}}></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="limit">Số lượng cần lấy</label>
                <input name="limit" type="number" id="limit" className="form-control" defaultValue={1000}/>
            </div>
            <div className="form-group">
                <label htmlFor="inputKeyword">Từ khóa</label>
                <input name="keyword" type="text" id="inputKeyword" className="form-control" placeholder="Từ khóa" />
            </div>
        </div>
    );
  }
}
