import React from "react";

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, Radio, InputNumber, Select } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled } from '@ant-design/icons';

export default class FilterData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            if (this.form) this.form.resetFields();
        }
    }
    render() {
        let { queryFilter, type } = this.props;
        queryFilter = queryFilter ? queryFilter : {};
        let form = null;
        if (type == 'scan_interactive_fanpage' || type == 'scan_interactive_group' || type == 'scan_interactive_post') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    count_like: queryFilter['count_like'],
                    count_comment: queryFilter['count_comment'],
                    count_share: queryFilter['count_share'],
                    __typename: queryFilter['__typename'] || ""
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="count_like">Lượt like</Select.Option>
                        <Select.Option value="count_comment">Lượt Comment</Select.Option>
                        {type != 'scan_interactive_group' ? <Select.Option value="count_share">Lượt Share</Select.Option> : null}
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                {/* {
                    type != 'scan_interactive_group' ?
                        <Form.Item name="__typename" label="Loại User">
                            <Select>
                                <Select.Option value="">Tất cả</Select.Option>
                                <Select.Option value="User">User</Select.Option>
                                <Select.Option value="Page">Page</Select.Option>
                            </Select>
                        </Form.Item>
                        : null
                } */}

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item name="count_like" label={`Lượt like`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                    <div className="col-md-4">
                        <Form.Item name="count_comment" label={`Lượt comment`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                    {
                        type != 'scan_interactive_group' ?
                            <div className="col-md-4">
                                <Form.Item name="count_share" label={`Lượt share`}>
                                    <InputNumber />
                                </Form.Item>
                            </div>
                            : null
                    }
                </div>
            </Form>
        } else if (type == 'search_fanpage') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    likes: queryFilter['likes'],
                    followers_count: queryFilter['followers_count'],
                    country: queryFilter['country'] || ""
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="likes">Lượt like</Select.Option>
                        <Select.Option value="followers_count">Lượt theo dõi</Select.Option>
                        <Select.Option value="page_created_timestamp">Ngày tạo</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item name="likes" label={`Lượt like`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                    <div className="col-md-4">
                        <Form.Item name="followers_count" label={`Lượt theo dõi`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item name="country" label={`Country`}>
                    <Select>
                        <Select.Option value="">Tất cả</Select.Option>
                        <Select.Option value="Vietnam">Vietnam</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        } else if (type == 'scan_friends') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    mutual_friends: queryFilter['mutual_friends']
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="mutual_friends">Bạn chung</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item name="mutual_friends" label={`Bạn chung`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        } else if (type == 'search_group') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    member_count: queryFilter['member_count'],
                    type_group: queryFilter['type_group'] || ""
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="member_count">Thành viên</Select.Option>
                        <Select.Option value="created_timestamp">Ngày tạo</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="type_group" label="Loại nhóm">
                    <Select>
                        <Select.Option value="">Tất cả</Select.Option>
                        <Select.Option value="OPEN">Công khai</Select.Option>
                        <Select.Option value="CLOSED">Nhóm riêng tư</Select.Option>

                    </Select>
                </Form.Item>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item name="member_count" label={`Số thành viên`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        } else if (type == 'me_likes') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    likes: queryFilter['likes'],
                    followers_count: queryFilter['followers_count']
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="likes">Lượt like</Select.Option>
                        <Select.Option value="followers_count">Lượt theo dõi</Select.Option>
                        <Select.Option value="page_created_timestamp">Ngày tạo</Select.Option>
                        <Select.Option value="created_timestamp">Ngày Like</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item name="likes" label={`Lượt like`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                    <div className="col-md-4">
                        <Form.Item name="followers_count" label={`Lượt theo dõi`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        } else if (type == 'me_groups') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    member_count_min: queryFilter['member_count_min'],
                    member_count_max: queryFilter['member_count_max'],
                    type_group: queryFilter['type_group'] || "",
                    viewer_post_status: queryFilter['viewer_post_status'] || "",
                    location: queryFilter['location'] || ""
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="member_count">Thành viên</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="type_group" label="Loại nhóm">
                    <Select>
                        <Select.Option value="">Tất cả</Select.Option>
                        <Select.Option value="OPEN">Công khai</Select.Option>
                        <Select.Option value="CLOSED">Nhóm riêng tư</Select.Option>
                        <Select.Option value="SECRET">Nhóm Bí mật</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="viewer_post_status" label="Kiểm duyệt">
                    <Select>
                        <Select.Option value="">Tất cả</Select.Option>
                        <Select.Option value="CAN_POST_WITHOUT_APPROVAL">CAN_POST_WITHOUT_APPROVAL</Select.Option>
                        <Select.Option value="CANNOT_POST">CANNOT_POST</Select.Option>
                        <Select.Option value="CAN_POST_AFTER_APPROVAL">CAN_POST_AFTER_APPROVAL</Select.Option>
                    </Select>
                </Form.Item>

                <div>
                    <label>Thành viên:</label>
                    <div className="d-flex align-items-center">
                        <Form.Item name="member_count_min" className="mr-10">
                            <InputNumber placeholder="Min" />
                        </Form.Item>
                        <Form.Item name="member_count_max">
                            <InputNumber placeholder="Max" />
                        </Form.Item>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-8">
                        <Form.Item name="uid"
                            label="UID"
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className="col-md-4">
                        <Form.Item name="location"
                            label="Vị trí"
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>

            </Form>
        } else if (type == 'info_uid') {
            form = <Form
                initialValues={{
                    gender: queryFilter['gender'] || ""
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="gender" label="Giới tính">
                    <Select>
                        <Select.Option value="">Tất cả</Select.Option>
                        <Select.Option value="male">Nam</Select.Option>
                        <Select.Option value="female">Nữ</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        } else if (type == 'scan_comment') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    gender: queryFilter['gender'] || "",
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="created_timestamp">Thời gian bình luận</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="gender" label="Giới tính">
                    <Select>
                        <Select.Option value="">Tất cả</Select.Option>
                        <Select.Option value="male">Nam</Select.Option>
                        <Select.Option value="female">Nữ</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        } else if (type == 'info_groupid') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    member_count: queryFilter['member_count'],
                    type_group: queryFilter['type_group'] || ""
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="member_count">Thành viên</Select.Option>
                        <Select.Option value="created_timestamp">Ngày tạo</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="type_group" label="Loại nhóm">
                    <Select>
                        <Select.Option value="">Tất cả</Select.Option>
                        <Select.Option value="OPEN">Công khai</Select.Option>
                        <Select.Option value="CLOSED">Nhóm riêng tư</Select.Option>
                        <Select.Option value="SECRET">Nhóm Bí mật</Select.Option>
                    </Select>
                </Form.Item>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item name="member_count" label={`Số thành viên`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        } else if (type == 'info_pageid') {
            form = <Form
                initialValues={{
                    sort_type: queryFilter['sort_type'] ? queryFilter['sort_type'] : "1",
                    sort_name: queryFilter['sort_name'] ? queryFilter['sort_name'] : "",
                    likes: queryFilter['likes'],
                    followers_count: queryFilter['followers_count']
                }}
                ref={(evt) => this.form = evt}
            >
                <Form.Item name="sort_name" label="Sắp xếp">
                    <Select>
                        <Select.Option value="">Mặc định</Select.Option>
                        <Select.Option value="likes">Lượt like</Select.Option>
                        <Select.Option value="followers_count">Lượt theo dõi</Select.Option>
                        <Select.Option value="page_created_timestamp">Ngày tạo</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="sort_type">
                    <Radio.Group>
                        <Radio value="1">{`A --> Z`}</Radio>
                        <Radio value="2">{`Z --> A`}</Radio>
                    </Radio.Group>
                </Form.Item>

                <div className="row">
                    <div className="col-md-4">
                        <Form.Item name="likes" label={`Lượt like`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                    <div className="col-md-4">
                        <Form.Item name="followers_count" label={`Lượt theo dõi`}>
                            <InputNumber />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        }
        return (
            <Modal
                title="Filter"
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
                className="filter-modal"
            >
                <div className="filter-modal-body">
                    {form}
                </div>
                <div className="d-flex align-items-center justify-content-between mt-20">
                    <Button type="danger" style={{ maxWidth: "200px", borderRadius: "5px", height: "40px", width: "50%" }} onClick={() => {
                        this.props.saveFilter(null);
                        setTimeout(() => {
                            this.form.resetFields();
                        }, 100)
                    }}>Bỏ Lọc</Button>
                    <Button type="primary" style={{ maxWidth: "200px", borderRadius: "5px", height: "40px", width: "50%" }} onClick={() => this.props.saveFilter(this.form.getFieldsValue())}>Lọc</Button>
                </div>
            </Modal>
        );
    }
}
