import React from "react";
import { DatePicker, Checkbox, Col, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';

const { RangePicker } = DatePicker;

export default class InterActive extends React.Component {
  constructor(props) {
    super(props);
    let now = Date.now()
    this.state = {
      since: now - (60 * 1000 * 60 * 24 * 30 * 6),
      until: now
    };
  }
  render() {
    let title = "Quét người tương tác với group";
    if (this.props.type == 'fanpage') title = "Quét người tương tác với fanpage";

    return (
      <div>
        <div className="pb-20">
          <h3 className="block-title block-title-m ">{title}</h3>
        </div>
        <div className="form-group">
          {
            this.props.userScan ?
              <div className="alert mt-5 pt-2 pb-2" style={{ background: "#eff2f5" }}>
                {this.props.userScan.id} - {this.props.userScan.name}
              </div> : null
          }
          {
            this.props.errorToken ?
              <div className="alert mt-5 pt-2 pb-2 alert-danger">
                {this.props.errorToken}
              </div> : null
          }
          <label htmlFor="inputToken">Nhập token</label>
          <input name="access_token" type="text" id="inputToken" className="form-control" placeholder="Nhập token" defaultValue={this.props.userScan && this.props.userScan.access_token} />
        </div>
        <div className="form-group">
          <label >Nhập cookie</label>
          <textarea name="cookie" type="text" className="form-control" placeholder="Nhập cookie" defaultValue={this.props.userScan && this.props.userScan.cookie} style={{ minHeight: "100px" }}></textarea>
        </div>
        {
          this.props.type == 'group' ? <div className="form-group">
            <label htmlFor="groupId">ID Group</label>
            <input name="page_id" type="text" id="groupId" className="form-control" placeholder="" />
          </div> : this.props.type == 'fanpage' ? <div className="form-group">
            <label htmlFor="pageId">ID Fanpage</label>
            <input name="page_id" type="text" id="pageId" className="form-control" placeholder="" />
          </div> : ''
        }
        <div className="form-group">
          <label>Thời gian tương tác</label>
          <div>
            <input type="hidden" name="until" value={this.state.until} />
            <input type="hidden" name="since" value={this.state.since} />
            <RangePicker
              locale={locale}
              defaultValue={[moment(this.state.since), moment(this.state.until)]}
              format={'DD-MM-YYYY'}
              ranges={{
                // 'Hôm nay': [moment(), moment()],
                'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
              }}
              onChange={(moments, dateString) => {
                this.setState({
                  since: moments[0].startOf('day').unix() * 1000,
                  until: moments[1].endOf('day').unix() * 1000
                })
              }}
              format="DD/MM/YYYY"
              allowClear={false}
              separator='~'

            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="limit">Giới hạn</label>
          <input type="number" id="limit" className="form-control" name="limit" />
        </div>

        <div className="form-group">
          <label>Chọn loại tương tác</label>
          <div>
            <Row>
              <Col span={8}>
                <Checkbox name="type_interactive" value="like" style={{ lineHeight: '32px' }}>
                  Like
                </Checkbox>
              </Col>
              {
                this.props.type != 'group' ? <Col span={8}>
                  <Checkbox name="type_interactive" value="share" style={{ lineHeight: '32px' }}>
                    Share
                  </Checkbox>
                </Col> : null
              }
              <Col span={8}>
                <Checkbox name="type_interactive" value="comment" style={{ lineHeight: '32px' }}>
                  Comment
                </Checkbox>
              </Col>
            </Row>
          </div>
        </div>

      </div>
    );
  }
}
