import React from "react";

export default class MeLike extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <div>
            <div className="pb-20">
                <h3 className="block-title block-title-m ">Quét fanpage UID thích</h3>
            </div>
            <div className="form-group">
                {
                  this.props.userScan ?
                  <div className="alert mt-5 pt-2 pb-2" style={{background: "#eff2f5"}}>
                  {this.props.userScan.id} - { this.props.userScan.name }
                  </div> : null
                }
                {
                  this.props.errorToken ? 
                  <div className="alert mt-5 pt-2 pb-2 alert-danger">
                  {this.props.errorToken}
                  </div> : null
                }
                <label htmlFor="inputToken">Nhập token</label>
                <input name="access_token" type="text" id="inputToken" className="form-control" placeholder="Nhập token" defaultValue={this.props.userScan && this.props.userScan.access_token} />
            </div>
            <div className="form-group">
                <label >Nhập cookie</label>
                <textarea name="cookie" type="text" className="form-control" placeholder="Nhập cookie" defaultValue={this.props.userScan && this.props.userScan.cookie} style={{minHeight: "100px"}}></textarea>
            </div>
            <div className="form-group">
                <label>UID</label>
                <input type="text" className="form-control" placeholder="" name="uid"/>
            </div>
            
        </div>
    );
  }
}
