import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, DeleteOutlined, FacebookFilled} from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Form, Select, Input} from 'antd';
import moment from 'moment';
import { ExportData } from '../component';
import { fetchData } from '../lib/apis';
import qs from 'qs';

const { Option} = Select;

export default class ScanComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0
        };
        this.limit = 20;
        this.page = 1;
        this.order_id = this.props.match.params.order_id;
    }
    componentWillMount = () => {
        this.getData();
    }
    getData = async () => {
        try {
            this.setState({
                loading: true
            })
            let query = {
                limit: this.limit,
                page: this.page
            }
            if(this.formFilter){
                query = {
                    ...query,
                    ...this.formFilter.getFieldsValue()
                }
            }
            let response = await fetchData({
                url: `api/v2/scan/comment/${this.order_id}?${qs.stringify(query)}`,
                method: 'get'
            });
            setTimeout(() => {
                this.setState({
                    listData: response.data,
                    order: response.order,
                    loading: false,
                    total: response.total
                })
            }, 600)
        } catch (err) {
            this.setState({
                loading: false
            })
        }
    }
    onExport = async (option) => {
        try{
            let { typeExport } = option;
            if(typeExport == 2){
                this.props.handleLoadingExport(true);
                let limit = 500;
                let page = 1;
                let next_page = true;
                let data = [];

                while(next_page){
                    let query = {
                        limit: limit,
                        page: page
                    }
                    if(this.formFilter){
                        query = {
                            ...query,
                            ...this.formFilter.getFieldsValue()
                        }
                    }
                    let response = await fetchData({
                        url: `api/v2/scan/comment/${this.order_id}?${qs.stringify(query)}`,
                        method: 'get'
                    });
                    data = data.concat(response.data);
                    if(response.data.length == 0) next_page = false;
                    page++;
                    await this.props.sleep(300);
                }
                option['data'] = data;
                this.props.onExport(option);
            } else{
                this.props.onExport(option);
            }
        } catch(err){
            this.props.handleLoadingExport(false);
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Lấy dữ liệu export',
                type: 'error'
            })
        }
    }
    render() {
        let { listData } = this.state;

        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <ExportData visible={this.state.visibleExport} hide={() => this.setState({ visibleExport: false })} type={'data_comment'}
                    onExport={this.onExport} data={listData} loading={this.props.loadingExport} formatExport={this.props.formatExport}
                    typeExport={2} totalExport={this.state.total}
                />
                <div className="block block-rounded">
                    <div className="block-header pb-0 justify-content-between align-items-center">
                        <div>
                            <h3 className="block-title block-title-m " >Dữ liệu quét comment ẩn</h3>
                        </div>
                        <Link to="/app/scan-comment-hidden" className="">
                            <i className="fa fa-arrow-circle-left"></i> Quay lại
                        </Link>
                    </div>
                    <div className="block-content">
                        <div className="mb-20">
                            <span style={{fontSize: "18px"}} className="alert alert-primary d-flex align-items-center"><FacebookFilled className="mr-10" style={{fontSize: "22px"}}/> Facebook: {this.state.order ? <a href={`https://fb.com/${this.state.order.query.post_id}`} target="_blank" className="ml-5">{this.state.order.query.post_id}</a> : '' }</span>
                        </div>
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <button className={`btn btn-primary btn-sm d-flex`}>
                                    Tất cả: {this.state.total}
                                </button>
                                <Tooltip title="Xuất dữ liệu">
                                    <button type="button" className={`btn btn-success btn-sm d-flex align-items-center ml-5`} onClick={() => this.setState({ visibleExport: true})}>
                                        <DownloadOutlined className="mr-5" /> Tải xuống
                                    </button>
                                </Tooltip>
                                <Tooltip title="Làm mới">
                                    <button type="button" className={`btn btn-primary btn-sm d-flex align-items-center ml-5`} onClick={() => this.getData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </Tooltip>
                            </div>
                            <div className={`${this.props.loading ? 'data-loading' : ''}`}>
                                <Pagination
                                    total={this.state.total}
                                    defaultCurrent={1}
                                    showSizeChanger={true}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getData();
                                    }}
                                    // simple
                                    pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                    onShowSizeChange={(current, size) => {
                                        this.limit = size;
                                        this.getData();
                                    }}
                                />
                            </div>
                        </div>
                        <Form
                            initialValues={{
                                is_phone: "",
                                is_email: ""
                            }}
                            onChange={(e) => {
                                if(e.target.nodeName == 'INPUT'){
                                    if(this.timeout) clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        this.getData();
                                    }, 300)
                                } else {
                                    this.getData();
                                }
                            }}
                            ref={(evt) => this.formFilter = evt}
                        >
                            <div className="row tool-filter">
                                <div className="col-md-2 col-lg-2 pl-15 pr-5">
                                    <Form.Item name="content">
                                        <Input placeholder="Nội dung"/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="is_phone">
                                        <Select onChange={(e) => {
                                            this.getData();
                                        }}>
                                            <Option value="">STD</Option>
                                            <Option value={1}>Có SDT</Option>
                                            <Option value={2}>Không có SDT</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="is_email">
                                        <Select onChange={(e) => {
                                            this.getData();
                                        }}>
                                            <Option value="">Email</Option>
                                            <Option value={1}>Có Email</Option>
                                            <Option value={2}>Không có Email</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Xóa lọc">
                                        <Button className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getData();
                                        }}></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>
                        <div className="table-responsive">
                            <Spin tip="loading.." spinning={this.state.loading}>
                                <table className="table table-striped table-vcenter table-text-center table-white-space">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>UID</th>
                                            <th>Tên</th>
                                            <th>Content</th>
                                            <th>SDT trong comment</th>
                                            <th>EMAIL trong comment</th>
                                            <th>Ngày tạo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listData.length > 0 ? listData.map((item, i) => {
                                                return <tr key={item._id}>
                                                    <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                    <td>{item.from ? item.from.id : ''}</td>
                                                    <td>{item.from ? item.from.name : ''}</td>
                                                    <td className="text-center">
                                                        <div className="d-flex justify-content-center">
                                                            <textarea disabled style={{ width: "300px", height: "60px" }} className="form-control">{item.content}</textarea>
                                                        </div>
                                                    </td>
                                                    <td>{item.phone_in_content}</td>
                                                    <td>
                                                        {item.email_in_content}
                                                    </td>
                                                    <td>{moment(item.created_time_fb).format('HH:mm DD-MM-YYYY')}</td>
                                                </tr>
                                            }) : <tr>
                                                    <td colSpan={11}>Chưa có dữ liệu</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
