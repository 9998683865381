import React from "react";
import { Button, notification, Row, Col, Checkbox} from 'antd';

export default class ScanComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    
  }
  render() {
    return (
        <div>
            <div className="pb-20">
                <h3 className="block-title block-title-m ">Quét comment bài viết</h3>
            </div>
            <div className="form-group">
                {
                  this.props.userScan ?
                  <div className="alert mt-5 pt-2 pb-2" style={{background: "#eff2f5"}}>
                  {this.props.userScan.id} - { this.props.userScan.name }
                  </div> : null
                }
                {
                  this.props.errorToken ? 
                  <div className="alert mt-5 pt-2 pb-2 alert-danger">
                  {this.props.errorToken}
                  </div> : null
                }
                <label htmlFor="inputToken">Nhập token</label>
                <input name="access_token" type="text" id="inputToken" className="form-control" placeholder="Nhập token" defaultValue={this.props.userScan && this.props.userScan.access_token} />
            </div>
            <div className="form-group">
                <label >Nhập cookie</label>
                <textarea name="cookie" type="text" className="form-control" placeholder="Nhập cookie" defaultValue={this.props.userScan && this.props.userScan.cookie} style={{minHeight: "100px"}}></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="postId">Id Bài viết</label>
                <input name="post_id" type="text" id="postId" className="form-control" placeholder="" />
            </div>
            <div className="form-group">
                <label htmlFor="limit">Giới hạn</label>
                <input name="limit" type="number" id="limit" className="form-control" />
            </div>
        </div>
    );
  }
}
