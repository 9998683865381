import React, { Component } from 'react';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, Radio } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled } from '@ant-design/icons';

export default class exportOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            loading: false,
            format_text: '',
            fileExtension: '.txt',
            format_export: [],
            typeExport: 1
        }
        this.form = null;
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            this.setState({
                format_text: '',
                format_export: []
            })
            if (this.form) this.form.resetFields();
        }
    }
    render() {
        const { type } = this.props;
        var checkboxs = [];
        if (type == 'search_fanpage') {
            checkboxs = ["id", "name", "emails", "phone", "likes", "followers_count", "link", "location", "category", "products",
                "about", "description", "page_created_time"
            ];
        } else if (type == 'scan_interactive_fanpage' || type == 'scan_interactive_group' || type == 'scan_interactive_post') {
            checkboxs = ["id", "name", "profile_picture.uri", "count_like", "count_comment", "count_share"];
        } else if (type == 'scan_member_group') {
            checkboxs = ["id", "name", "profile_picture.uri", "bio_text.text", "friendship_status"];
        } else if (type == 'scan_phone') {
            checkboxs = ["uid", "phone", "type_phone"];
        } else if (type == 'scan_friends') {
            checkboxs = ["id", "name", "mutual_friends.count", "friendship_status", "subscribe_status"];
        } else if (type == 'search_group') {
            checkboxs = ["id", "name", "email", "member_count", "privacy", "created_time", "description", "viewer_join_state"];
        } else if (type == 'me_likes') {
            checkboxs = ["id", "name", "emails", "phone", "likes", "followers_count", "link", "location", "category",
                "products", "about", "description", "page_created_time", "like_time"
            ];
        } else if (type == 'info_uid') {
            checkboxs = ["id", "name", "gender", "email", "birthday", "locale", "address", "avatar", "friends_count"];
        } else if (type == 'scan_comment') {
            checkboxs = ["id", "name", "content", "gender", "email_in_content", "phone_in_content", "created_time"];
        } else if (type == 'me_groups') {
            checkboxs = ["uid", "id", "name", "member_count", "privacy", "viewer_post_status", "location"];
        } else if (type == 'info_groupid') {
            checkboxs = ["id", "name", "email", "member_count", "privacy", "viewer_post_status", "created_time"];
        } else if (type == 'info_pageid') {
            checkboxs = ["id", "name", "emails", "phone", "likes", "followers_count", "link", "location", "category",
                "products", "about", "description", "page_created_time"
            ];
        } else if (type == 'data_comment') {
            checkboxs = ["post_id", "from.id", "from.name", "content", "comment_id", "phone_in_content", "email_in_content"]
        }
        return (
            <Modal
                title="Export"
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
            >
                <div className="export-option">
                    {
                        this.props.typeExport == 2 ?
                            <div className="title">
                                <Radio.Group defaultValue={1} onChange={(e) => this.setState({ typeExport: e.target.value })}>
                                    <Radio value={1}>Export ({this.props.data ? this.props.data.length : 0})</Radio>
                                    <Radio value={2}>Export tất cả ({ this.props.totalExport })</Radio>
                                </Radio.Group>
                            </div>
                            :
                            <div className="title">
                                <Button size={"small"} type="white" icon={<DownSquareOutlined />} shape={'round'}
                                >
                                    Dữ liệu được export ({this.props.data ? this.props.data.length : 0})
                                </Button>
                            </div>
                    }

                </div>
                <div className="export-container">
                    <div className="format mb-15" >
                        <Form ref={(evt) => this.form = evt}
                            initialValues={{
                                format_text: checkboxs.map((item) => { return this.props.formatExport[item] || item}).join(' | '),
                                format_export: checkboxs
                            }}
                        >
                            <Form.Item name="format_export">
                                <Checkbox.Group className="format mb-15" onChange={(e) => {
                                    this.form.setFieldsValue({
                                        ["format_text"]: e.map((item) => { return this.props.formatExport[item] || item}).join(' | ')
                                    })
                                }} style={{ width: '100%' }} >
                                    <Row>
                                        {
                                            checkboxs.map((item, i) => {
                                                return <Col span={8} key={item + '_' + i}>
                                                    <Checkbox value={item} style={{ lineHeight: '32px' }}>
                                                        {this.props.formatExport[item] || item}
                                                    </Checkbox>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item name="format_text">
                                <Input disabled={true} />
                            </Form.Item>

                            <div className="mt-10">
                                <Radio.Group onChange={(e) => this.setState({ fileExtension: e.target.value })} value={this.state.fileExtension}>
                                    <Radio value={'.txt'}>File txt</Radio>
                                    <Radio value={'.xlsx'}>File excel</Radio>
                                </Radio.Group>
                            </div>
                        </Form>
                    </div>
                    <div className="export-header text-center">
                        <Button type="primary" icon={<VerticalAlignBottomOutlined />} onClick={() => {
                            let format_export = this.form.getFieldValue('format_export');
                            this.props.onExport({
                                data: this.props.data,
                                format_export: format_export,
                                fileExtension: this.state.fileExtension,
                                typeExport: this.state.typeExport
                            })
                        }} loading={this.props.loading}

                        >
                            Tải xuống
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
