import React from "react";
import { Select, Form, Input, Table, Button } from 'antd';

import { DownloadOutlined, FilterFilled } from '@ant-design/icons';

export default class TopInteractive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div className="row flex-md-row" style={{ height: "100%" }}>
                <div className="col-md-4">
                    <div className="block block-rounded d-flex">
                        <div className="block-content">
                            <div className="pb-20">
                                <h3 className="block-title block-title-m ">Top tương tác</h3>
                            </div>
                            <Form 
                                initialValues={{
                                    type_id: "fanpage",
                                    ids: "",
                                    token_ids: [],
                                    type: this.props.type_app
                                }}
                                ref={(e) => this.form = e}
                            >
                                <Form.Item noStyle name="type">
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="token_ids" label="Token chỉ định">
                                    <Select mode="multiple" 
                                        loading={this.props.loadingGetToken}
                                        showSearch
                                        onSearch={this.props.getListToken}
                                        filterOption={false}
                                        defaultActiveFirstOption={false}
                                        maxTagCount={3}
                                    >
                                        {
                                            this.props.listToken.map((item) => {
                                                return <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item name="type_id" label="Loại">
                                    <Select>
                                        <Select.Option value="fanpage">Fanpage</Select.Option>
                                        <Select.Option value="group">Group</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="ids" label="List id">
                                    <Input.TextArea style={{minHeight: "250px"}}/>
                                </Form.Item>
                                <div className="d-flex">
                                    <Button style={{marginRight: "10px"}} onClick={this.props.showGuide}>Hướng dẫn</Button>
                                    {
                                        this.state.loadingScan ?
                                        <Button type="ghost" onClick={this.props.stopScan}>Dừng quét</Button> :
                                        <Button type="primary" onClick={() => {
                                            this.props.startScan(this.form)
                                        }}>Quét</Button>
                                    }
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="block block-rounded mb-0">
                        <div className="block-header pb-0 justify-content-between align-items-center">
                            <h3 className="block-title">Kết quả: <span className="text-success">{this.props.listData.length}</span></h3>
                        </div>
                        <div className="block-content">
                            <div className={`mb-10 d-flex align-items-center`}>
                                <button type="button" className={`btn btn-success btn-sm mt-0 p-2 d-flex align-items-center`} onClick={() => this.props.visibleExport(this.props.listData)}>
                                    <DownloadOutlined className="mr-5" /> Tải xuống
                                </button>
                            </div>
                            <Table
                                dataSource={this.props.listData}
                                columns={[
                                    {
                                        title: "#",
                                        key: "#",
                                        render: (item, value, index) => {
                                            return index + 1;
                                        }
                                    },
                                    {
                                        title: "Id",
                                        key: "id",
                                        dataIndex: "id"
                                    },
                                    {
                                        title: "Like",
                                        key: "like",
                                        dataIndex: "count_like"
                                    },
                                    {
                                        title: "Comment",
                                        key: "comment",
                                        dataIndex: "count_comment"
                                    },
                                    {
                                        title: "Tổng tương tác",
                                        key: "sum_interactive",
                                        dataIndex: "sum_interactive"
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
