import React, { Component } from 'react';
import { Modal, Button} from 'antd';
import NumberFormat from 'react-number-format';
import { fetchData } from '../lib/apis';

export default class DetailPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.form = null;
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            this.setState({
                loading: false
            })
        }
    }
    createTransaction = async () => {
        try {
            this.setState({
                loading: true
            })
            let res = await fetchData({
                url: `api/v2/service/transaction`,
                method: 'post',
                data: JSON.stringify({
                    package_id: this.props.createPayment.package_id
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.props.showDetailPayment(true, res.data, null, true);
            this.setState({
                loading: false
            })
        } catch (err) {
            this.setState({
                loading: false
            })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Nâng cấp gói cước',
                type: 'error'
            })
        }
    }
    render() {
        return (
            <Modal
                title={this.props.createPayment ? this.props.createPayment.title : "Chi tiết thanh toán"}
                visible={this.props.showPayment}
                onCancel={this.props.hide}
                footer={null}
            >
                <div className="money-wallet">
                    <p className="">
                        <strong>Bước 1</strong>: Đăng nhập vào Internet banking ( ngân hàng điện tử )
                                                        </p>
                    <p className="">
                        <strong>Bước 2</strong>: Tạo giao dịch chuyển tiền tới một trong các tài khoản sau:
                                                        </p>
                    <div className="block-header-default block-rounded p-3 mb-3">
                        {
                            this.props.service.banking ?
                                this.props.service.banking.map((item, i) => {
                                    item = item.split('|');
                                    return <p key={i}>
                                        Ngân hàng: {item[2] || ''}<br></br>
                                        Chủ TK: {item[1] || ''}<br></br>
                                        STK: {item[0] || ''}<br></br>
                                    </p>
                                })
                                : null
                        }
                    </div>
                    <p>
                        <strong>Bước 3</strong>: Nhập nội dung chuyển khoản chính xác như sau:
                    </p>
                    {
                        this.props.createPayment && !this.props.createPayment.create ? 
                        <div className="text-center">
                            <Button loading={this.state.loading} type="primary" onClick={this.createTransaction}>Lấy thông tin thanh toán</Button>
                        </div> : this.props.detailBanking ? <div className="show-order">
                            <h3 className="block-title mb-2">Thông tin chi tiết thanh toán</h3>
                            <p className="mb-2"><strong>- Nội dung chuyển khoản là:</strong> <span className="text-danger">{this.props.detailBanking.code}</span></p>
                            <p><strong>- Số tiền cần thanh toán là:</strong> <span className="text-danger"><NumberFormat value={this.props.detailBanking.amount} displayType={'text'} thousandSeparator={true} />đ</span></p>
                            <div className="block-header-default block-rounded p-3">
                                <p className="mb-0 text-danger">Chú ý:</p>
                                <p className="mb-0 text-danger">**Vui lòng nhập chính xác nội dung chuyển khoản như trên.</p>
                                <p className="mb-0">Sau khi nhận được thông báo từ ngân hàng và gói cước được xác thực, hệ thống sẽ tự động kích hoạt gói cước sau 2-5 phút. Trong trường hợp bạn điền sai thông tin hoặc có bất kỳ sự cố gì khiến hệ thống không thể tự động kích hoạt, vui lòng Liên hệ <a href={this.props.service.link_support} target="_blank">Fanpage</a> để được hỗ trợ sớm nhất</p>
                            </div>
                        </div> : null
                    }
                </div>
            </Modal>
        )
    }
}
