import React, { Component } from 'react';
import {AccountMetaData, getCookie, setCookie, checkIssetLogin} from '../config/app';

import { fetchData } from '../lib/apis';
import { Button } from 'antd';
import { FacebookFilled } from '@ant-design/icons'

export default class Login extends Component{
  constructor(props){
    super(props);
    this.state = {
        text_error: null,
        loading: false
    }
  }
  componentWillMount = async () => {
    try{
        if(AccountMetaData.checkIssetLogin()){
            this.props.history.push('/me');
            return
        }
        let { search, hash } = this.props.location;
        let params = new URLSearchParams(search);
        if(params.get('error')){
            this.props.notification({
                title: 'Đăng nhập',
                content: 'Đăng nhập thất bại',
                type: "error"
            });
        } else if(hash.indexOf('#') > -1){
            this.setState({ loading: true });

            setTimeout(async () => {
                try{
                    let result = new URLSearchParams(hash.replace('#', '?'));
                    let response = await fetchData({
                        url: 'api/v2/login',
                        method: 'post',
                        data: JSON.stringify({
                            access_token: result.get('access_token'),
                            data_access_expiration_time: result.get('data_access_expiration_time'),
                            expires_in: result.get('expires_in'),
                            ref: getCookie('ref')
                        }),
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    let user = response.user;

                    setCookie('token', response.token, 7);
                    setCookie('auth.token.facebook', response.token_facebook, 7);
                    setCookie('usr', encodeURIComponent(JSON.stringify(user)), 7);

                    window.location.href = "/me";
                } catch(err){
                    this.setState({
                        loading: false
                    })
                    this.props.notification({
                        title: 'Đăng nhập',
                        content: 'Đăng nhập thất bại',
                        type: "error"
                    });
                }
            }, 500)
        }
    } catch(err){
        this.setState({
            loading: false
        })
        this.props.notification({
            title: 'Đăng nhập',
            content: 'Đăng nhập thất bại',
            type: "error"
        });
    }
  }
  componentDidMount = () => {
    
  }

  login = async () => {
      AccountMetaData.logIn();
  }

  render(){
    return(
        <div className="bg-image bg-pattern" style={{ backgroundImage: "url('/media/photos/photo34@2x.jpg')"}}>
            <div className="row mx-0 justify-content-center bg-white-op-95">
                <div className="hero-static col-lg-6 col-xl-4">
                    <div className="content content-full overflow-hidden">
                        <div className="py-30 text-center">
                            {
                                window.location.origin.indexOf('forisoftware') > -1 ? 
                                <a className="link-effect text-pulse font-w700" href="index.php">
                                    <i className="si si-fire"></i>
                                    <span className="font-size-xl text-pulse-dark">Fori</span><span className="font-size-xl text-pulse-light">Software</span>
                                </a>
                                : window.location.origin.indexOf('iseeding') > -1 ? 
                                <a className="link-effect text-pulse font-w700" href="index.php">
                                    <i className="si si-fire"></i>
                                    <span className="font-size-xl text-pulse-dark">VinhTool</span><span className="font-size-xl text-pulse-light">Scan</span>
                                </a> : <a className="link-effect text-pulse font-w700" href="/">
                                    <i className="si si-fire"></i>
                                    <span className="font-size-xl text-pulse-dark">Smart</span><span className="font-size-xl text-pulse-light">Target</span>
                                </a>
                            }
                            {/* <h1 className="h4 font-w700 mt-30 mb-10">Smart target</h1> */}
                        </div>
                        <form className="js-validation-lock" novalidate="novalidate">
                            <div className="block block-themed block-rounded block-shadow">
                                <div className="block-header">
                                    <h3 className="block-title">Đăng nhập</h3>
                                </div>
                                <div className="block-content">
                                    <div className="form-group text-center">
                                        <img className="img-avatar img-avatar96" src="/media/avatars/avatar15.jpg" alt="" />
                                    </div>
                                    <div className="form-group text-center">
                                        <Button className="d-flex align-items-center m-auto" icon={<FacebookFilled className="d-flex"/>} type="primary" loading={this.state.loading}
                                            onClick={this.login}
                                        >Đăng nhập với facebook</Button>
                                    </div>
                                </div>
                                <div className="block-content bg-body-light">
                                    <div className="form-group text-center">
                                        <a className="link-effect text-muted mr-10 mb-5 d-inline-block" href="#">
                                        <i className="fa fa-user text-muted mr-5"></i> Đăng nhập để sử dụng dịch vụ
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
