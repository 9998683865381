import React from "react";
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount = () => {
        document.querySelectorAll('#sidebar .nav-main li').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                document.querySelector('#page-container').classList.remove('sidebar-o-xs');
            })
        })
        document.querySelectorAll('[data-toggle="sidebar"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                let action = this.getAttribute('data-action');
                if (action == 'sidebar_hide') {
                    document.querySelector('#page-container').classList.remove('sidebar-o');
                    document.querySelector('#page-container').classList.remove('sidebar-o-xs');
                }
                if (action == 'sidebar_toggle') {
                    let page_container = document.querySelector('#page-container');
                    if (window.innerWidth > 992) {
                        if (page_container.classList.contains('sidebar-o')) {
                            page_container.classList.remove('sidebar-o');
                        } else {
                            page_container.classList.add('sidebar-o');
                        }
                    } else {
                        if (page_container.classList.contains('sidebar-o-xs')) {
                            page_container.classList.remove('sidebar-o-xs');
                        } else {
                            page_container.classList.add('sidebar-o-xs');
                        }
                    }

                }
            })
        })
        document.querySelectorAll('.nav-submenu[data-toggle="nav-submenu"]').forEach((anchor) => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                let list_class = e.target.parentNode.getAttribute('class');
                if (list_class.indexOf('open') > -1) {
                    e.target.parentNode.classList.remove('open');
                } else {
                    e.target.parentNode.classList.add('open');
                }
            })
        })
    }
    render() {
        let pathname = window.location.pathname.replace(/\/$/g, '');
        let menu_admin = [
            <li key={2}>
                <Link to="/admin/transaction" className={pathname == '/admin/transaction' ? "active" : ""}>
                    <span>Đơn hàng</span>
                </Link>
            </li>,
            <li key={3}>
                <Link to="/admin/users" className={pathname == '/admin/users' ? "active" : ""}>
                    <span>Người dùng</span>
                </Link>
            </li>
        ]
        return (
            <nav id="sidebar">
                <div className="sidebar-content">
                    <div className="content-header justify-content-start">
                        {/* <h2 className="title">Smart Target</h2>
                        <button type="button" className="btn btn-circle btn-dual-secondary align-v-r d-lg-none btn-hide-sidebar" data-toggle="sidebar" data-action="sidebar_hide">
                            <i className="fa fa-times"></i>
                        </button> */}
                        <Link className="img-link mr-15" to="/me">
                            <img className="img-avatar" src={this.props.user.picture} alt="" style={{ width: "35px", height: "35px" }} />
                        </Link>
                        {
                            this.props.user.package ?
                            <div>
                                <p className="m-0">Gói: { this.props.user.package.name }</p>
                                {
                                    this.props.user.package.end_time < Date.now() ? 
                                    <p className="m-0 text-danger">
                                        <Link to="/package">
                                        Nâng cấp để sử dụng.
                                        </Link>
                                    </p>
                                    :
                                    <p className="m-0">Ngày hết hạn: { moment(this.props.user.package.end_time).format('DD-MM-YYYY') }</p>
                                }
                            </div> :
                            <div>
                                <p className="m-0">Bạn chưa đăng ký gói cước nào.</p>
                            </div>
                        }
                        <button type="button" className="btn btn-circle btn-dual-secondary align-v-r d-lg-none btn-hide-sidebar" data-toggle="sidebar" data-action="sidebar_hide">
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                    <div className="content-side content-side-full">
                        <ul className="nav-main">
                            {
                                this.props.user.is_owner || this.props.user.is_root ?
                                <li className="pt-10 nav-main-heading">
                                    <span>Quản trị</span>
                                </li> : null
                            }
                            {
                                this.props.user.is_owner ? menu_admin : null
                            }
                            {
                                this.props.user.is_root ? 
                                <li>
                                    <Link to="/admin/proxy" className={pathname == '/admin/proxy' ? "active" : ""}>
                                        <span>Proxy</span>
                                    </Link>
                                </li> : null
                            }
                            <li className="pt-10 nav-main-heading border-t">
                                <span>Hỗ trợ</span>
                            </li>
                            <li>
                                <a href={this.props.service.link_ext_token} target="_blank">
                                    <span>Tiện ích lấy token - cookie</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://docs.google.com/document/d/1SBJFaKOphjCR9c0GoYi-teVmdranob-Y2_RziuS19cI/edit?usp=sharing" target="_blank">
                                    <span>Hướng dẫn sử dụng</span>
                                </a>
                            </li>
                            <li className="pt-10 nav-main-heading border-t">
                                <span>Công cụ</span>
                            </li>
                            <li>
                                <Link to="/app/findid" className={pathname == '/app/findid' ? "active" : ""}>
                                    <span>Find ID</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/me/token" className={pathname == '/me/token' ? "active" : ""}>
                                    <span>Quản lý token</span>
                                </Link>
                            </li>
                            <li className={pathname == '/app/search-fanpage' || pathname == '/app/interactive-fanpage' || pathname == '/app/me-likes' 
                                || pathname == '/app/detail-pageid'
                            ? "open" : ""}>
                                <a className="nav-submenu" data-toggle="nav-submenu" href="#">Quét Fanpage</a>
                                <ul>
                                    <li>
                                        <Link to="/app/search-fanpage" className={pathname == '/app/search-fanpage' ? "active" : ""} >
                                            <span>Tìm fanpage theo từ khóa</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/interactive-fanpage" className={pathname == '/app/interactive-fanpage' ? "active" : ""}>
                                            <span>Quét người tương tác page</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/me-likes" className={pathname == '/app/me-likes' ? "active" : ""}>
                                            <span>Quét fanpage UID thích</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/detail-pageid" className={pathname == '/app/detail-pageid' ? "active" : ""}>
                                            <span>Quét thông tin fanpage</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={pathname == '/app/member-group' || pathname == '/app/interactive-group' || pathname == '/app/search-group'
                                || pathname == '/app/me-groups' || pathname == '/app/detail-groupid' ? "open" : ""}>
                                <a className="nav-submenu" data-toggle="nav-submenu" href="#">Quét Group</a>
                                <ul>
                                    <li>
                                        <Link to="/app/member-group" className={pathname == '/app/member-group' ? "active" : ""}>
                                            <span>Quét thành viên</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/interactive-group" className={pathname == '/app/interactive-group' ? "active" : ""}>
                                            <span>Quét người tương tác</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/search-group" className={pathname == '/app/search-group' ? "active" : ""}>
                                            <span>Tìm group theo từ khóa</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/me-groups" className={pathname == '/app/me-groups' ? "active" : ""}>
                                            <span>Quét group của UID</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/detail-groupid" className={pathname == '/app/detail-groupid' ? "active" : ""}>
                                            <span>Quét thông tin group</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={pathname == '/app/interactive-post' || pathname == '/app/scan-comment'
                                || pathname == '/app/scan-friends' || pathname == '/app/detail-uid' ? "open" : ""}>
                                <a className="nav-submenu" data-toggle="nav-submenu" href="#">Quét tương tác post</a>
                                <ul>
                                    <li>
                                        <Link to="/app/interactive-post" className={pathname == '/app/interactive-post' ? "active" : ""}>
                                            <span>Quét tương tác bài viết</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/scan-comment" className={pathname == '/app/scan-comment' ? "active" : ""}>
                                            <span>Quét comment bài viết</span>
                                        </Link>
                                    </li>
                                </ul>   
                            </li>
                            <li className={pathname == '/app/interactive-post' || pathname == '/app/scan-comment'
                                || pathname == '/app/scan-friends' || pathname == '/app/detail-uid' ? "open" : ""}>
                                <a className="nav-submenu" data-toggle="nav-submenu" href="#">Quét bạn bè / UID</a>
                                <ul>
                                    <li>
                                        <Link to="/app/scan-friends" className={pathname == '/app/scan-friends' ? "active" : ""}>
                                            <span>Quét danh sách bạn bè</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app/detail-uid" className={pathname == '/app/detail-uid' ? "active" : ""}>
                                            <span>Quét thông tin UID</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {/* <li>
                                <Link to="/app2/interactive" className={pathname == '/app2/interactive' ? "active" : ""} >
                                    <span>Top tương tác</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/app/scan-comment-hidden" className={pathname == '/app/scan-comment-hidden' ? "active" : ""} >
                                    <span>Quét comment ẩn</span>
                                </Link>
                            </li>
                            {
                                this.props.user.is_scanphone ?
                                <li>
                                    <Link to="/app/scan-phone" className={pathname == '/app/scan-phone' ? "active" : ""} >
                                        <span>Convert uid {`->`} số điện thoại</span>
                                    </Link>
                                </li> : null
                            }
                            <li>
                                <Link to="/app/filter-files" className={pathname == '/app/filter-files' ? "active" : ""}>
                                    <span>Lọc dữ liệu</span>
                                </Link>
                            </li>
                            <li className="pt-10 nav-main-heading border-t">
                                <span>Cá nhân</span>
                            </li>
                            <li>
                                <Link to="/me" className={pathname == '/me' ? "active" : ""}>
                                    <span>Thông tin</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/package" className={pathname == '/package' ? "active" : ""}>
                                    <span>Giao dịch - Gói cước</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/history" className={pathname == '/history' ? "active" : ""}>
                                    <span>Lịch sử quét</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
