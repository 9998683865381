import React from "react";
import NumberFormat from 'react-number-format';
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default class OwnerTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            detailBanking: null,
            valuesFilter: {},
            services: [],
            total: 0
        };
        this.limit = 15;
        this.page = 1;
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            let query = `limit=${this.limit}&page=${this.page}`;
            for (let [key, value] of Object.entries(this.state.valuesFilter)) {
                query += `&${key}=${value}`;
            }
            let response = await fetchData({
                url: `api/v2/ow/transaction?${query}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                services: response.services
            })
        } catch (err) {

        }
    }
    updateTransaction = async (e, item) => {
        e.preventDefault();
        let _this = this;
        Modal.confirm({
            title: 'Xác nhận thanh toán',
            icon: <ExclamationCircleOutlined />,
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v2/ow/transaction/${item._id}`,
                            method: 'post',
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });
                        _this.getListData();
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Xác nhận thanh toán',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 200)
    }
    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <div className="block block-rounded">
                    <div className="block-header">
                        <h3 className="block-title">Lịch sử giao dịch: {this.state.total}</h3>
                        {
                            this.state.total > 0 ?
                                <Pagination
                                    total={this.state.total}
                                    defaultCurrent={this.page}
                                    showSizeChanger={false}
                                    pageSize={this.limit}
                                    current={this.page}
                                    onChange={(current) => {
                                        this.page = current;
                                        this.getListData();
                                    }}
                                    simple
                                /> : null
                        }
                    </div>
                    <div className="block-header">
                        <div className="d-flex align-items-center">
                            <input placeholder="Tìm theo mã" style={{ width: "250px", marginRight: "10px" }} onChange={this.handleFilterSearch} name="code" className="form-control" />
                            <select className="form-control" name="status" onChange={this.handleFilterSearch}>
                                <option value="">Trạng thái</option>
                                <option value={0}>Chưa thanh toán</option>
                                <option value={1}>Đã thanh toán</option>
                            </select>
                            {
                                this.props.user.is_root ?
                                <select className="form-control ml-10" name="service_id" onChange={this.handleFilterSearch}>
                                    <option value="">Domain</option>
                                    {
                                        this.state.services.map((item, i) => {
                                            return <option key={i} value={item._id}>{item.domain}</option>
                                        })
                                    }
                                </select> : null
                            }
                        </div>
                    </div>
                    <div className="block-content">
                        <div className="table-responsive">
                            <table className="table table-striped table-vcenter table-text-center table-package table-white-space">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Mã</th>
                                        {
                                            this.props.user.is_root ? 
                                            <th>Domain</th> : null
                                        }
                                        {/* <th>Người tạo</th> */}
                                        <th>Gói</th>
                                        <th>Số tiền</th>
                                        <th>Ngày tạo</th>
                                        <th>Ngày thanh toán</th>
                                        <th>Trạng thái</th>
                                        <th>Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                            return <tr key={i}>
                                                <td>{this.page > 1 ? ((this.limit * this.page) - this.limit) + i + 1 : i + 1}</td>
                                                <td>{item.code}</td>
                                                {
                                                    this.props.user.is_root ? 
                                                    <td>{item.service ? item.service.domain : null}</td> : null
                                                }
                                                {/* <td>{item.created_name}</td> */}
                                                <td>{item.package.name}</td>
                                                <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /> đ</td>
                                                <td>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                                <td>{item.time_payment ? moment(item.time_payment).format('HH:mm DD-MM-YYYY') : ""}</td>
                                                <td>{item.status == 0 ? <span className="">Chờ thanh toán</span> : item.status == 1 ? <span className="text-success">Đã thanh toán</span> : <span className="text-danger">Bị hủy</span>}</td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        {item.status == 0 ? <a style={{ color: "#fff" }} className="btn btn-noborder btn-rounded btn-primary btn-sm"
                                                            onClick={(e) => this.updateTransaction(e, item)}
                                                        >Xác nhận</a> : null}
                                                    </div>
                                                </td>
                                            </tr>
                                        }) : <tr>
                                                <td colSpan={11}>Chưa có dữ liệu</td>
                                            </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
